<template>
  <section class="team-section sec-pad bg-color-1">
    <div class="pattern-layer" style="background-image: url(assets/images/shape/shape-5.png);"></div>
    <div class="auto-container">
      <div class="sec-title">
        <h2>Meet Council Members</h2>
        <div class="title-shape"></div>
        <a href="index.html" class="theme-btn">All members</a>
      </div>
      <div class="row clearfix">
        <div class="col-lg-3 col-md-6 col-sm-12 team-block">
          <div class="team-block-one wow fadeInUp animated animated" data-wow-delay="00ms" data-wow-duration="1500ms">
            <div class="inner-box">
              <figure class="image-box"><a href="index.html"><img src="assets/images/team/team-1.jpg" alt=""></a>
              </figure>
              <div class="lower-content">
                <div class="author-box">
                  <h4><a href="index.html">Bertram Irvin</a></h4>
                  <span class="designation">President</span>
                </div>
                <ul class="othre-info clearfix">
                  <li class="mail-box"><a href="index.html"><i class="flaticon-mail-inbox-app"></i>Email</a></li>
                  <li class="phone-box"><i class="flaticon-emergency-call"></i><a href="tel:4488812345">+44 888 12
                    345</a></li>
                  <li class="share-option">
                    <i class="share-icon flaticon-share"></i>
                    <ul class="share-links clearfix">
                      <li><a href="index.html"><i class="fab fa-pinterest-p"></i></a></li>
                      <li><a href="index.html"><i class="fab fa-twitter"></i></a></li>
                      <li><a href="index.html"><i class="fab fa-facebook-f"></i></a></li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 team-block">
          <div class="team-block-one wow fadeInUp animated animated" data-wow-delay="200ms"
               data-wow-duration="1500ms">
            <div class="inner-box">
              <figure class="image-box"><a href="index.html"><img src="assets/images/team/team-2.jpg" alt=""></a>
              </figure>
              <div class="lower-content">
                <div class="author-box">
                  <h4><a href="index.html">Elvina Julie</a></h4>
                  <span class="designation">Actuary</span>
                </div>
                <ul class="othre-info clearfix">
                  <li class="mail-box"><a href="index.html"><i class="flaticon-mail-inbox-app"></i>Email</a></li>
                  <li class="phone-box"><i class="flaticon-emergency-call"></i><a href="tel:4488812345">+44 888 12
                    345</a></li>
                  <li class="share-option">
                    <i class="share-icon flaticon-share"></i>
                    <ul class="share-links clearfix">
                      <li><a href="index.html"><i class="fab fa-pinterest-p"></i></a></li>
                      <li><a href="index.html"><i class="fab fa-twitter"></i></a></li>
                      <li><a href="index.html"><i class="fab fa-facebook-f"></i></a></li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 team-block">
          <div class="team-block-one wow fadeInUp animated animated" data-wow-delay="400ms"
               data-wow-duration="1500ms">
            <div class="inner-box">
              <figure class="image-box"><a href="index.html"><img src="assets/images/team/team-3.jpg" alt=""></a>
              </figure>
              <div class="lower-content">
                <div class="author-box">
                  <h4><a href="index.html">Herman Gordon</a></h4>
                  <span class="designation">Director</span>
                </div>
                <ul class="othre-info clearfix">
                  <li class="mail-box"><a href="index.html"><i class="flaticon-mail-inbox-app"></i>Email</a></li>
                  <li class="phone-box"><i class="flaticon-emergency-call"></i><a href="tel:4488812345">+44 888 12
                    345</a></li>
                  <li class="share-option">
                    <i class="share-icon flaticon-share"></i>
                    <ul class="share-links clearfix">
                      <li><a href="index.html"><i class="fab fa-pinterest-p"></i></a></li>
                      <li><a href="index.html"><i class="fab fa-twitter"></i></a></li>
                      <li><a href="index.html"><i class="fab fa-facebook-f"></i></a></li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 team-block">
          <div class="team-block-one wow fadeInUp animated animated" data-wow-delay="600ms"
               data-wow-duration="1500ms">
            <div class="inner-box">
              <figure class="image-box"><a href="index.html"><img src="assets/images/team/team-4.jpg" alt=""></a>
              </figure>
              <div class="lower-content">
                <div class="author-box">
                  <h4><a href="index.html">Marian Lenora</a></h4>
                  <span class="designation">Speaker</span>
                </div>
                <ul class="othre-info clearfix">
                  <li class="mail-box"><a href="index.html"><i class="flaticon-mail-inbox-app"></i>Email</a></li>
                  <li class="phone-box"><i class="flaticon-emergency-call"></i><a href="tel:4488812345">+44 888 12
                    345</a></li>
                  <li class="share-option">
                    <i class="share-icon flaticon-share"></i>
                    <ul class="share-links clearfix">
                      <li><a href="index.html"><i class="fab fa-pinterest-p"></i></a></li>
                      <li><a href="index.html"><i class="fab fa-twitter"></i></a></li>
                      <li><a href="index.html"><i class="fab fa-facebook-f"></i></a></li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "TeamSection"
}
</script>

<style scoped>

</style>