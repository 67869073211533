<template>
  <div class="xs-sidebar-group info-group info-sidebar">

    <div class="xs-overlay xs-bg-black"></div>

    <div class="xs-sidebar-widget" style="width: 100%!important; min-width: 400px!important;">

      <a href="#" class="close-side-widget float-right m-3" style="z-index: 200" id="closeItem">
        <i class="fal fa-times"></i>
      </a>
      <section class="faq-section sec-pad">
        <div class="auto-container">
          <div class="row clearfix">
            <a href="/">
              <img src="/image/Logotransparente.png" alt="" width="200px"
                   style="margin-top: -60px; margin-bottom: 20px">
            </a>

            <div class="col-lg-12 col-md-12 col-sm-12 content-column  ">
              <div class="content_block_8">
                <div class="content-box p-0 m-0">

                  <div class="sec-title">

                    <div class="title-shape"></div>
                  </div>
                  <div class="text"></div>
                </div>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 faq-column" style="width: 100%; min-width: 300px!important; ">

              <div class="dropdown" v-for="item in this.data.mainMenu" :key="item.id"
                   style="width:  100%!important; min-width: 100%!important;">
                <h1>
                  <!-- :to="(item.class==='dropdown'?'#':item.url)" -->
                  <router-link :to="(item.class==='dropdown'?'#':'/'+item.url)"
                               class="text-uppercase text-white routeClique">
                    {{ item.context }}
                  </router-link>
                </h1>
                <ul v-if="item.class === 'dropdown'" class="acc-content"
                    style="width: 360px!important; min-width: 360px!important;">
                  <li v-for="e_item in item.elements" :key="e_item.id" class="text-white close-parente">
                    <router-link v-if="e_item.type === 1" :to="{name:'HistoricoParlamento', path:'/'+ e_item.url }"
                                 class="text-white  ">
                      {{ e_item.context }}
                    </router-link>
                    <router-link v-else :to="{path:'/'+ e_item.url }" class="text-white routeClique "
                                 v-on:click="newView">
                      {{ e_item.context }}
                    </router-link>


                    <ul v-if="e_item.class === 'dropdown'" class="acc-content mt-3"
                        style="">

                      <li v-for="e_item_n in e_item.elements" :key="e_item_n.id" class="text-white netos-styles">
                        <router-link v-if="e_item_n.type === 1"
                                     :to="{name:'HistoricoParlamento', path:'/'+ e_item_n.url }"
                                     class="text-white  ">
                          {{ e_item_n.context }}
                        </router-link>
                        <router-link v-else :to="{path:'/'+ e_item_n.url }" class="text-white routeClique "
                                     v-on:click="newView">
                          {{ e_item_n.context }}
                        </router-link>
                      </li>


                    </ul>


                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>

import api from "../services/api";

export default {
  name: "SideBarViews",
  data() {
    return {
      data: [],
      loop: 1
    }
  },
  methods: {
    newView: function () {
      window.jQuery(document).ready((function ($) {
        $('#closeItem').click();
      }));
    },
    initserve: function () {
      try {
        api.get('/MainMenuFull').then(response => {
          this.data = response.data.data

        });
      } catch (e) {
        console.log(e)
      }
    },
    initquery: function () {

      try {
        window.jQuery(document).ready((async function ($) {
          $('.routeClique').click(() => {
            $('#closeItem').click();
          })
        }));
      } catch (e) {
        console.log(e)
      }
    }
  },
  mounted() {
    this.initquery()
    this.initserve()


  }
}
</script>

<style scoped>
.dropdown {
  margin: 0px auto;
  width: 300px;
}

.dropdown:hover h1 {
  border-radius: 5px 5px 0px 0px;
}

.netos-styles {
  display: none !important;
  transition: all 0.5s linear 0s;
  transition-duration: 0.2s, 0.4s;
}

.close-parente ul li {
  cursor: pointer;
  opacity: 0;
  transition-property: all, background-color;
  transition-duration: 0.2s, 0.4s;
}

.close-parente:hover .netos-styles {
  display: block !important;
  transition: all 0.5s linear 0s;
  transition-duration: 0.2s, 0.4s;
}

.dropdown:hover li {
  padding: 10px;
  border-top: 1px #383838 solid;
  height: auto;
  overflow: auto;
  opacity: 1;
}

.dropdown h1 {
  display: inline-block;
  padding: 10px;
  position: relative;
  background: transparent;
  color: #ffffff;
  font-size: 16px;
  font-weight: normal;
  text-align: left;
  border: 5px;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  transition: all 0.5s linear 0s;
}

.dropdown h1 b {
  color: #eee;
  font-weight: normal;
}

.dropdown h1:before {
  /* content: "";*/
  margin-top: -2px;
  margin-right: 10px;
  display: inline-block;
  background: #a3a6a9;
  border-radius: 5px;
  vertical-align: middle;
  width: 16px;
  height: 16px;
}

.dropdown h1.selected-1:before {
  background: #5790e6;
}

.dropdown h1.selected-2:before {
  background: #8773eb;
}

.dropdown h1.selected-3:before {
  background: #b27cd9;
}

.dropdown h1.selected-4:before {
  background: #e884b0;
}

.dropdown h1.selected-5:before {
  background: #eda180;
}

.dropdown ul {
  display: block;
  position: relative;
  list-style: none;
}

.dropdown li {
  padding: 0px 10px;
  background: transparent;
  color: transparent;
  text-align: left;
  border: 0px;
  font-size: 16px;
  width: 100%;
  height: 0px;
  overflow: hidden;
  cursor: pointer;
  opacity: 0;
  transition-property: all, background-color;
  transition-duration: 0.2s, 0.4s;
}


.dropdown li b {
  color: #eee;
  font-weight: normal;
}

.dropdown li:hover, .dropdown li.selected {
  background-color: #292929;
}

.dropdown li:last-child {
  border-radius: 0px 0px 5px 5px;
}

.dropdown li:before {
  content: "";
  margin-top: -2px;
  margin-right: 10px;
  display: inline-block;
  border-radius: 5px;
  vertical-align: middle;
  width: 16px;
  height: 16px;
}

.dropdown li:nth-child(1) {
  transition-delay: 0s, 0s;
}

.dropdown li:nth-child(1):before {
  background: #5790e6;
}

.dropdown li:nth-child(2) {
  transition-delay: 0.1s, 0s;
}

.dropdown li:nth-child(2):before {
  background: #8773eb;
}

.dropdown li:nth-child(3) {
  transition-delay: 0.2s, 0s;
}

.dropdown li:nth-child(3):before {
  background: #b27cd9;
}

.dropdown li:nth-child(4) {
  transition-delay: 0.3s, 0s;
}

.dropdown li:nth-child(4):before {
  background: #e884b0;
}

.dropdown li:nth-child(5) {
  transition-delay: 0.4s, 0s;
}

.dropdown li:nth-child(5):before {
  background: #eda180;
}

.dropdown li:before {
  background: #369758;
}

</style>
