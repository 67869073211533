<template>
  <div class="menu-right-content clearfix mobile-active">
    <div class="language-box" style="margin-right: -15px!important;">
      <span class="text" v-if="data.list_lange[0]">
        <i class="flaticon-world"></i>{{ data.list_lange[0].context }}
      </span>
      <ul class="language-list clearfix" v-if="data.list_lange[0]">
        <li-config :list-links="data.list_lange"/>
      </ul>
    </div>



<!--        Forma certa com search de pesquisa-->
<!--    <ul class="other-option clearfix">
      <li class="search-btn">teste
        <button type="button" class="search-toggler"><i class="far fa-search"></i></button>
      </li>
      <li class="nav-box">
        <div class="nav-toggler navSidebar-button"><i class="fas fa-th-large"></i></div>
      </li>
    </ul>
  </div>-->
    <!--    Botão de todos menus sem o search de pesquisa-->

<!--
    <ul class="other-option clearfix">
      <li>
        <span class="shimmer-animation" style="color: red;">Todos Menus-></span>
      </li>
      <li class="nav-box">
        <div class="nav-toggler navSidebar-button"><i class="fas fa-th-large"></i></div>
      </li>
    </ul>
    -->



<!--    <ul class="other-option clearfix">
      <li>
        <span class="shimmer-animation" style="color: red;">Todos Menus</span>
        <span class="arrow-animation">&#10230;</span>
      </li>
      <li class="nav-box">
        <div  class="nav-toggler navSidebar-button"><i class="fas fa-th-large"></i></div>
      </li>
    </ul>
  </div>-->

    <ul class="other-option clearfix">
      <li>
<!--        <span class="shimmer-animation ">+ Menus</span>
        <span class="arrow-animation brilliant-red">&#10230;</span>-->
      </li>
      <li class="search-btn">
        <button type="button" class="search-toggler"><i class="far fa-search"></i></button>
      </li>
      <li class="nav-box">
        <div class="nav-toggler navSidebar-button "><i class="fas fa-bars"></i></div>
      </li>

    </ul>
  </div>


</template>

<script>

import LiList from "./LiList";

export default {
  name: "MenuRightContent",
  props: {
    data: {
      list_lange: []
    }
  },
  components: {
    'li-config': LiList,
  },

}
</script>
<style scoped>
@keyframes shimmer {
  0% {
    opacity: 0.5;
    color: red;
  }
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.shimmer-animation {
  animation: shimmer 1s infinite;
}
</style>
<style scoped>
@media (max-width: 1201px) {
  .mobile-active {
    float: right !important;
    position: absolute;
    right: 10px;
    top: 18%;
  }
}
@media (max-width: 575px){
  .mobile-active{
    float: right !important;
    position: absolute;
    right: -5px;
    top: 35%;
  }
}
</style>


<style scoped>
@keyframes arrowMove {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}

.arrow-animation {
  display: inline-block;
  animation: arrowMove 1s infinite;
}
</style>


------------------------------------------------------------------

<style scoped>
@keyframes shimmer {
  0% {
    opacity: 0.2;
    color: darkred;
  }
  50% {
    opacity: 1;
    color: darkred;
  }
  100% {
    opacity: 0.2;
    color: darkred;
  }
}

.shimmer-animation {
  animation: shimmer 5s infinite;
}

.brilliant-red {
  color:  darkred ;
  text-shadow: 0 0 5px darkred;
}
</style>


