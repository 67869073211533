import {createRouter, createWebHistory} from 'vue-router'
import Home from '../views/clients/home/Index'
import HistoricoParlamento from "../views/clients/parlamento/HistoricoParlamento";
import AllItem from "../views/clients/components/AllItem";
import Multimidea from "../views/clients/parlamento/Multimidea";
import ListDeputy from "../views/clients/parlamento/ListDeputy";
import Contexto from "../views/clients/parlamento/Contexto";
import Imagens from "../views/clients/components/Imagens";
import AudioViews from "../views/clients/components/AudioViews";
import VideoViews from "../views/clients/components/VideoViews";
import IntervencaoView from "../views/clients/components/IntervencaoView";
//import AgendaParlamentarViews from "../views/clients/components/AgendaParlamentarViews";
import ConvocatoriaViews from "../views/clients/components/ConvocatoriaViews";
import DeputyView from "../views/clients/components/DeputyView";
import ProfileDeputy from "../views/clients/components/ProfileDeputy";
import Contacts from "../components/Contacts";


const routes = [

    {
        path: '/',
        name: 'IndexViews',
        component: Home
    },
    {
        path: '/contacto/forms',
        name: 'Contact',
        component: Contacts
    },
    {
        path: "/Historico_parlamento",
        name: 'HistoricoParlamento',
        component: HistoricoParlamento
    },
    {
        path: "/multimedia",
        name: "Multimidea",
        component: Multimidea,
        children: [
            {
                path: '',
                name: 'AllItem',
                component: AllItem,
            },
            {
                path: 'imagens',
                name: 'Imagens',
                component: Imagens,
            },
            {
                path: 'audios',
                name: 'AudioViews',
                component: AudioViews,
            },
            {
                path: 'videos',
                name: 'VideoViews',
                component: VideoViews,
            }

        ]
    },
    {
        path: "/Deputados",
        name: 'Deputados',
        component: ListDeputy,
        children: [
            {
                path: ':Deputado',
                name: 'DeputyView',
                component: DeputyView,
            }
        ]
    },
    {
        path: "/:documentario",
        name: 'Contexto',
        component: Contexto
    },
    {
        path: "/entidades/:deputyprofile",
        name: 'ProfileDeputyInfo',
        component: ProfileDeputy
    },
    {
        path: "/intervencaoRYS",
        name: 'IntervencaoView',
        component: IntervencaoView
    },

    {
        path: "/convocatoriaTR",
        name: 'ConvocatoriaViews',
        component: ConvocatoriaViews
    }

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})


/*
MainMenuJSON.mainMenu.forEach(function (e) {
    if (e.elements)
        e.elements.forEach(function (d) {
            if (d.type === 1)
                router.addRoute({
                    path: "/" + d.url,
                    component: HistoricoParlamento
                })

            else if (d.type === 2)
                router.addRoute({
                    path: "/" + d.url,
                    component: ListDeputy
                })
            else if (d.type === 3)
                router.addRoute({
                    path: "/" + d.url,
                    component: Multimidea,
                    children: [
                        {
                            path: '',
                            name: 'todos',
                            component: AllItem,
                        }
                    ]
                })

            else
                router.addRoute({
                    path: "/" + d.url,
                    component: Contexto
                })
        })

})


router.addRoute(
    {
        path: '/multimedia',
        name: 'Home',
        component: Multimidea,
        children: [
            {
                path: '',
                name: 'todos',
                component: AllItem,
            }
        ]
    })
*/

export default router
