<template xmlns:max-width="http://www.w3.org/1999/xhtml" xmlns:max-height="http://www.w3.org/1999/xhtml">

  <div class="schedule-block-one" v-for="item in dataList" :key="item.id">

    <div class="inner-box">
      <div class="image-box">
        <figure class="image"><img style="max-height:450px; min-height:450px" :src="this.dominio+item.url" alt="">
        </figure>

        <div class="content-box" style="margin-top: -100px">
          <div class="post-date" v-if="item.post_date !=null"><h3>{{ item.post_date.day }}
            <span>{{ item.post_date.month }}’{{ item.post_date.year }}</span></h3>
          </div>
          <div class="text">
            <span class="category">{{ item.category }}</span>
            <h4><a href="event-details.html">{{ item.details }}</a>
            </h4>
          </div>
        </div>
      </div>
      <div class="lower-content" style="margin-top: -100px; z-index: 1000">

        <ul class="social-style-one clearfix" v-if="item.tab_entity" style="min-height: 50px">
          <div v-if="item.tab_entity.socialites">
            <li v-for="socialite in item.tab_entity.socialites" :key="socialite.id" style="padding: 2px; margin: 0">
              <a :href="socialite.href"><i :class="socialite.icon"></i></a>
            </li>
          </div>
        </ul>
        <ul class="social-style-one clearfix" v-else>
          <li style="background-color: transparent!important;padding: 2px; margin: 0">
            <a href="#" style="background-color: transparent!important;">
              <i class="fab fa-bell" style="font-size: 23px"></i>
            </a>
          </li>
        </ul>
        <hr>
        <div style="min-height: 20px">
          <div class="share-box text-dark" style="margin-bottom: -10px">
            <a href="#">Partilhar<i class="fas fa-share-alt"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Util from "../../store/util";

export default {
  name: "ScheduleBlockOne",
  data() {
    return {
      dominio: Util.Domine
    }
  },
  props: {
    dataList: []
  }
}
</script>

<style scoped>

</style>
