<template>

  <div class="mt-3 mb-3" style="height: 30px" v-if="this.totalPages() > 0">
    <div class="pagination-wrapper float-right">
      <ul class="pagination clearfix">
        <li v-if="this.showPreviousLink()" v-on:click="updatePage(this.currentPage - 1)">
          <i class="far fa-angle-double-left cursor-pointe"></i>
        </li>
        <!--        current-->
        <li v-for="count in this.pageAdpter" :key="count" v-on:click="this.updatePage(count - 1)">
          <a href="javascript:void(0)" :class="count=== (this.currentPage+1)?'current':''">
            {{ count }}
          </a>
        </li>

        <li v-if="this.showNextLink()" v-on:click="this.updatePage(this.currentPage + 1)">
          <i class="far fa-angle-double-right cursor-pointe"></i>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  name: "PaginateComponents",
  props: ['list', 'currentPage', 'pageSize'],
  data() {
    return {
      pageAdpter: 0,
      totalPageData: 0,
      resquet: false
    }
  },

  methods: {

    updatePage(pageNumber) {
      this.$emit('page:update', pageNumber);
    },
    totalPages() {
      if (!this.resquet) {
        const tamanho = this.list.length
        const sizePage = this.pageSize

        this.totalPageData = Math.ceil(tamanho / sizePage);
        this.pageAdpter = this.totalPageData;
        this.resquet = true;
        if (this.totalPageData >= 5) {
          this.pageAdpter = 5
        }
      }
      return this.totalPageData;
    },
    showPreviousLink() {
      return this.currentPage !== 0;
    },
    showNextLink() {
      return this.currentPage !== (this.totalPages() - 1);
    }
  }
}
</script>

<style scoped>
.cursor-pointe {
  cursor: pointer;
}
</style>
