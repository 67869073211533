<template>
  <div>
    <div class="single-item style-one">
      <figure class="image-box"><img src="assets/images/resource/history-1.jpg" alt=""></figure>
      <div class="text">
        <span>1945</span>
        <h6>San Antonio</h6>
        <h4>City Council Founded</h4>
        <p>Denounce with righteous indignation dislike men who are so beguiled and demoralized by pleasure of
          the moment.</p>
      </div>
    </div>
    <div class="single-item style-two text-right">
      <figure class="image-box"><img src="assets/images/resource/history-2.jpg" alt=""></figure>
      <div class="text">
        <span>1969</span>
        <h6>Smart City</h6>
        <h4>Drawing Around the City</h4>
        <p>Owing to the claims of duty or the obligations business it will frequently occurs that have
          repudiated annoyances accepted.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HistoryLine"
}
</script>

<style scoped>

</style>