<template>
  <div>
    <section class="history-section">
      <figure class="image-layer bg-dark"><img src="assets/images/resource/history-5.jpg" alt=""></figure>
      <div class="auto-container">
        <div class="row clearfix ">
          <div class="col-lg-4 col-md-12 col-sm-12 title-column">
            <div class="sec-title">
              <h2>Most Memorable Moments</h2>
              <div class="title-shape"></div>
              <p>Righteous indignation & dislike men who are beguiled demoralized the charms.</p>
            </div>

          </div>
          <div class="col-lg-8 col-md-12 col-sm-12 inner-column mb-0 pb-0">
            <div class="inner-box">
              <history-line/>
              <history-line/>
              <div class="text-right p-0 mb-0">
                <router-link class="btn btn-danger btn-sm mt-5 mb-0 pl-5 pr-5 "
                             :to="'/register'">
                  ler mais
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>
  </div>
</template>

<script>
/* eslint-disable */
import HistoryLine from "@/components/subComponent/HistoryLine";


export default {
  name: "History",
  components: {
    "history-line": HistoryLine
  },
  props: {
    dataInfor: String,
  },
  mounted() {

  },
  created() {
    this.status = false;
  },
  data() {
    return {}
  }
}
</script>

<style scoped>

</style>
