<template>
  <div>

    <section class="sidebar-page-container sec-pad-2 pb-0 mb-0">
      <div class="auto-container">
        <div class="row clearfix">

          <div class="col-lg-8 col-md-12 col-sm-12 content-side border-right">
            <router-view :key="$route.path"></router-view>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12 sidebar-side ">
            <div class="blog-sidebar">

              <div class="sidebar-widget category-widget">

                <!--Titulo do perfil dos Deputados-->

                <div v-if="this.requestAwait">
                  <h3 v-if="this.visibleDeputies[0].category">{{
                      this.visibleDeputies[0].category.titleCapa
                    }} </h3>
                  <h3 v-else>Lista de Deputados</h3>
                </div>

                <div class="col-sm-6 col-12">
                  <hr>
                </div>
                <br>
                <div class="">
                  <!--  {{ this.dataList }} -->

                          <!--Meter os nomes por ordem Alfabetica no Portal Internet-->
                  <ul class="row justify-content-center" v-if="this.requestAwait">
                    <li :class="this.colConfig + ' mt-4 mb-4' " v-for="deputy in sortedDeputies" :key="deputy.id">

                      <div class="justify-content-center">

                        <div class="col-sm-12 p-0 justify-content-center">
                          <img style="border-radius: 5px!important; max-height: 220px!important; "
                               v-bind:src="this.dominT + deputy.imgCapa" :alt="deputy.title">
                        </div>
                        <div class="col-sm-12 p-0">
                          <span class="font-weight-bold mt-2 mb-3"
                                style="">{{
                              deputy.title.substring(0, 22) + (deputy.title.length > 22 ? '...' : '')
                            }}</span>
                          <br>

                          <div class="mt-3 ">

                            <socialite-info :socialites="[]"/>

                            <router-link :to="{name:'ProfileDeputyInfo', params: { deputyprofile: deputy.object_iuu } }"
                                         class="badge badge-danger font-weight-light p-2 float-right cursor-pointe  mb-2 linkes-col-marge mt-sm-0 mt-3">
                              Ver
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>

                  <ul class="row justify-content-center" v-else>
                    <li :class="this.colConfig + ' mt-4 mb-4' " v-for="i in this.conter" :key="i">
                      <div class="justify-content-center">

                        <div class="col-sm-12 p-0 justify-content-center" style="border-radius: 5px!important;
                        max-height: 240px; min-height: 240px; width: 240px; background-color: rgba(11,30,44,0.65)">
                          <div class="dots">
                            <div class="dot"></div>
                            <div class="dot"></div>
                            <div class="dot"></div>
                            <div class="dot"></div>
                            <div class="dot"></div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>


              </div>
            </div>
          </div>

        </div>
        <div v-if="this.openPaginate">
          <div class="mt-3 mb-3" style="height: 30px" v-if="this.totalPageData > 0">
            <div class="pagination-wrapper float-right">
              <ul class="pagination clearfix">
                <li v-if="this.showPreviousLink()" v-on:click="updatePage(this.currentPage - 1)">
                  <i class="far fa-angle-double-left cursor-pointe"></i>
                </li>
                <!--        current-->
                <li v-for="count in this.pageAdpter" :key="count" v-on:click="this.updatePage(count - 1)">
                  <a href="javascript:void(0)" :class="count=== (this.currentPage+1)?'current':''">
                    {{ count }}
                  </a>
                </li>

                <li v-if="this.showNextLink()" v-on:click="this.updatePage(this.currentPage + 1)">
                  <i class="far fa-angle-double-right cursor-pointe"></i>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="border-bottom"></div>

    </section>

  </div>
</template>

<script>

import Util from "../../../store/util";
import socialiteInfo from "./component/socialite-info";

export default {
  name: "DeputyContent",
  components: {socialiteInfo},
  props: {
    dataList: Object
  },
  data() {
    return {
      dominT: Util.Domine,
      nextId: 2,
      currentPage: 0,
      pageSize: 18,
      visibleDeputies: [],
      pageAdpter: 0,
      openPaginate: false,
      requestAwait: false,
      totalContente: 0,
      totalPageData: 0,
      conter: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
      colConfig: 'col-6 col-sm-2'

    }
  },
  async created() {

    this.updatedeVisibleDeputies()

    await this.delay(2000)
        .then(() => {
          this.requestAwait = true

        });


    await this.delay(2000)
        .then(() => {
          this.openPaginate = true;
          if (this.totalPageData < 1) {
            this.totalPageData = this.totalPages()
          }
        });

  },
 /* async computed() {

  },*/
  computed: {
    sortedDeputies() {
      return this.visibleDeputies.slice().sort((a, b) => a.title.localeCompare(b.title));
    }
  },
  methods: {
    totalPages() {
      if (this.totalPageData < 1) {

        const tamanho = this.dataList.length
        const sizePage = this.pageSize

        this.totalPageData = Math.ceil(tamanho / sizePage);
        this.pageAdpter = this.totalPageData;

        if (this.totalPageData >= 5) {
          this.pageAdpter = 5
        }
      }
      return this.totalPageData;
    },

    showPreviousLink() {
      return this.currentPage !== 0;
    },

    showNextLink() {
      return this.currentPage !== (this.totalPages() - 1);
    },

    delay(time) {
      return new Promise(resolve => setTimeout(resolve, time));
    },
    updatePage(pageNumber) {
      this.currentPage = pageNumber;
      this.updatedeVisibleDeputies();
    },
    updatedeVisibleDeputies() {
      const sizeVisible = this.visibleDeputies.length;
      const listData = this.dataList;
      this.visibleDeputies = listData.slice(this.currentPage * this.pageSize, (this.currentPage * this.pageSize) + this.pageSize)
      if (sizeVisible === 0 && this.currentPage > 0) {
        this.updatePage(this.currentPage - 1);
      }
    }
  },
}
</script>

<style scoped>

.img-contexts {
  max-height: 210px;
  min-height: 210px;
  width: 240px
}


.cursor-pointe {
  cursor: pointer;
}


@media (min-width: 920px) {
  .img-content {

    width: 340px;
    height: 330px;
    max-width: 340px;
    max-height: 330px;
  }
}


</style>
