<template>
  <div>
    <section class="events-grid sec-pad-2">
      <div class="auto-container">
        <div class="row clearfix">

          <div class="col-lg-4 col-md-6 col-sm-12 schedule-block" v-for="item in this.data" :key="item.id">
            <div class="schedule-block-one" v-if="item.typeMultimedia > 2 && item.typeMultimedia < 5 ">

              <div class="inner-box">
                <!--  assets/images/resource/schedule-2.jpg -->
                <div class="image-box">

                  <figure class="image" v-if="item.typeMultimedia >2">
                    <img v-if="item.urlFile" :src="dominio + item.urlFile" alt=""
                         style="min-width: 350px; max-width: 350px;  min-height: 321px; max-height:321px ">
                    <img v-else src="assets/images/resource/schedule-2.jpg" alt=""
                         style="min-width: 350px; max-width: 350px;  min-height: 321px; max-height:321px ">
                  </figure>
                  <figure class="image" v-else>
                    <img  src="assets/images/resource/schedule-2.jpg" alt=""
                          style="min-width: 350px; max-width: 350px;  min-height: 321px; max-height:321px ">
                  </figure>
                  <div class="content-box">
                    <!--                    <div class="post-date" v-if="item.created_at"><h3>28<span>Jan’21</span></h3></div>-->
                    <div class="text">
                      <h4><a href="#">{{ item.titleContext }}</a></h4>
                    </div>
                  </div>
                </div>
                <div class="lower-content">

                  <div class="links"><a href="#">Saiba Mais<i class="flaticon-right-arrow"></i></a>
                  </div>
                  <div class="share-box"><a href="#">Partilhe<i class="fas fa-share-alt"></i></a></div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import MultimideaJSON from "../../../json/MultimediaJSON.json";
import Util from "../../../store/util";
export default {
  name: "Imagens",
  data() {
    return {
      data: MultimideaJSON.data,
      dominio: Util.DomineTo
    }
  },
  created() {
    this.data = Util.Multimedia.data
  }
}
</script>

<style scoped>

</style>
