<template>
  <div>

    <sub-header :data="this.headerInfo" v-if="this.headerInfo.h1 ||this.headerInfo.img "/>

    <div v-if="this.headerInfo.codeTool">
      <todos-noticias-videos :noticias="this.bodyInfo"/>
    </div>
    <!-- {{ this.headerInfo.hasOwnProperty("codeTool") }} -->
    <div v-if="!this.headerInfo.hasOwnProperty('codeTool')">
      <div v-if="this.bodyInfo.length > 0">
        <sub-contexto :data="this.bodyInfo" v-if="!this.bodyInfo[0].entityType"/>
      </div>
    </div>

   
    <deputy-content :data-list="this.bodyInfo" v-if="this.bodyInfo[0].entityType"/>

    <blog-page-noticie :data="[]" :data-video="[]" v-if="!this.bodyInfo[0].entityType"/>

  </div>
</template>

<script>

import SubHeader from "@/components/subComponent/SubHeader";
import api from "../../../services/api";
import SubContexto from "../../../components/subComponent/SubContexto";
import DeputyContent from "./DeputyContent";
import BlogPageNotice from "../../../components/BlogPageNotice";
import TodosNoticiasVideos from "@/views/clients/parlamento/component/TodosNoticiasVideos";

export default {
  name: "Contexto",
  data() {
    return {
      idParameter: this.$route.params.documentario,
      headerInfo: Object,
      bodyInfo: Object,
      entidade: [],
      notices: [],
      h1: Storage,
      img: Storage
    }
  },
  components: {
    "sub-header": SubHeader,
    "sub-contexto": SubContexto,
    "deputy-content": DeputyContent,
    "blog-page-noticie": BlogPageNotice,
    TodosNoticiasVideos

  },
  async created() {

    const URL = this.$route.fullPath;

    await api.get(URL, {
      proxy: false
    }).then(async responce => {
      let reseve = responce.data;
      if (reseve.data.data) {

        this.headerInfo = reseve.data.data.header
        this.bodyInfo = reseve.data.data.body

        console.log(reseve.data.data.header, reseve.data.data.body)
      } else {
        this.bodyInfo = reseve.data
      }


      if (reseve.data.notices) {

        this.h1 = reseve.data.noticeCorrent.context;
        this.img = reseve.data.noticeCorrent.img;

        this.headerInfo = reseve.data.noticeCorrent.header
        //  console.log(reseve.data)
        this.bodyInfo = reseve.data.notices;
      }
    })


  },
  mounted() {


    window.jQuery(document).ready((async function ($) {

      $('.image-style-align-left').css({"float": "left", "margin-right": "10px"})

      $('.image_resized').css({"float": "left", "margin-right": "10px"})

    }))

  }
}
</script>

<style scoped>
figure {

}
</style>
