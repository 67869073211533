const cacheBrowser = {

    setCache() {
        window.sessionStorage.setItem('parlamento.ao.local', document.cookie)
    },
    getCache() {
        // eslint-disable-next-line no-global-assign
        window = JSON.parse(window.sessionStorage.getItem('parlamento.ao.local'))

    }
}

export default cacheBrowser;
