<template>
  <div v-if="data">
    <section class="sidebar-page-container sec-pad-2 bg-color-1">
      <div class="auto-container">
        <div class="row clearfix">
          <div class="col-lg-12 col-md-12 col-sm-12 content-side">

            <div class="content-side mb-4 bg-white p-2">
              <router-link to="noticia" class="badge badge-danger p-2">Todas as Notícias
              </router-link>
              &nbsp;

            </div>

            <div class="blog-details-content " v-if="this.visibleNoticie">
              <div class="row justify-content-center">
                <div class="col-12 col-lg-4 col-md-6 col-sm-6 content-side bg-white mb-4 "
                     v-for="data in this.visibleNoticie" :key="data.id"
                     style="border-radius: 10px">
                  <div class="inner-box">
                    <figure class="image-box p-0" style="max-height: 180px; min-height:180px;">
                      <img :src="dominio+ data.img" style="max-height: 180px; min-height:180px;"
                           alt=""></figure>
                    <h4 class="">
                      <span data-tooltip="{{ data.context }}" data-flow="right">{{
                          data.context.substr(0, 70) + (data.context.length > 70 ? ' ...' : ' ')
                        }}</span>
                    </h4>
                    <br>
                    <div class="text"
                         style="height: 170px!important; text-overflow: ellipsis;  overflow: hidden; ">
                      <p style="background-color: rgb(248,248,248); border-radius: 5px; padding: 10px;  color: #000000!important;"
                         v-html="data.destaque.substr(0,200)+' ...'"></p>

                    </div>
                    <div class="btn-box mt-4 mb-2" style="float: right">
                      <router-link :to="'Noticia_'+ data.id" class="badge badge-danger p-2">Mais informação
                      </router-link>
                    </div>
                    <div class="download-block-one row" v-if="data.anexolists">

                      <a :href="this.dominio + element.path " v-for="element in data.anexolists" :key="element.id"
                         download>
                        <figure class="icon-box mr-2 mt-3" style="cursor: pointer">
                          <img src="/assets/images/icons/icon-1.png" style="width: 36px" alt="">
                        </figure>
                      </a>
                    </div>

                  </div>
                </div>
              </div>
              <paginate-components
                  v-bind:list="this.noticiaInfo"
                  v-on:page:update="updatePage"
                  v-bind:current-page="this.currentPage"
                  v-bind:page-size="this.pageSize"/>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Util from "../store/util";
import api from "../services/api";
import PaginateComponents from "../views/clients/parlamento/component/PaginateComponents";

export default {
  name: "BlogPageNotice",
  props: {
    data: Object,
    dataVideo: Object
  },
  data() {
    return {
      dominio: Util.Domine,
      noticiaInfo: [],
      videoInfo: [],
      nextId: 2,
      currentPage: 0,
      pageSize: 3,
      visibleNoticie: [],
      nextIdVideo: 2,
      currentPageVideo: 0,
      pageSizeVideo: 3,
      visibleVideo: [],
      selectVideo: Object
    }
  },
  components: {
    PaginateComponents
  },
  async created() {
    await api.get('/noticia').then(async (response) => {
      this.noticiaInfo = await response.data.data.notices

      await this.updatedeVisibleNoticies()
    });


  },
  methods: {

    clickedIFram() {
      alert();
    },
    updatePage(pageNumber) {
      this.currentPage = pageNumber;
      this.updatedeVisibleNoticies();
    },
    updatedeVisibleNoticies() {
      this.visibleNoticie = this.noticiaInfo.slice(this.currentPage * this.pageSize, (this.currentPage * this.pageSize) + this.pageSize)
      if (this.visibleNoticie.length === 0 && this.currentPage > 0) {
        this.updatePage(this.currentPage - 1);
      }
    },
    updatePageVideo(pageNumber) {
      this.currentPageVideo = pageNumber;
      this.updatedeVisibleVideo();
    },
    updatedeVisibleVideo() {
      this.visibleVideo = this.videoInfo.body_header.slice(this.currentPageVideo * this.pageSizeVideo, (this.currentPageVideo * this.pageSizeVideo) + this.pageSizeVideo)
      if (this.visibleVideo.length === 0 && this.currentPageVideo > 0) {
        this.updatePageVideo(this.currentPageVideo - 1);
      }
    }

  }
}
</script>

<style scoped>

</style>
