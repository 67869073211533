<template>
  <div class="col-lg-3 col-md-6 col-sm-12 footer-column">
    <div class="footer-widget links-widget">
      <div class="widget-title">
        <h3 v-html="myTitle"></h3>
      </div>
      <div class="widget-content">
        <ul class="links-list clearfix">
          <li v-for="item in listItens" :key="item.id">
            <router-link :to="(item.url?item.url:'#')">{{ item.context }}</router-link>
          </li>

        </ul>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "FooterWidget",
  components: {},
  props: {
    myTitle: String,
    listItens: []
  }
}
</script>

<style scoped>

</style>
