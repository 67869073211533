<template>
  <section class="activities-section centred bg-color-1">
    <div class="auto-container">
      <div class="inner-container">
        <div class="row clearfix">
          <div class="col-lg-2 col-md-6 col-sm-6 col-6  single-column">
            <div class="single-item pt-4">
              <h6></h6>
              <router-link to="convocatoria">
                <h4 style="">
                  <div class="clipe-paragrafo text-uppercase">Convocatória</div>

                </h4>
              </router-link>
            </div>
          </div>


<!--
          <div class="col-lg-2 col-md-6 col-sm-6 col-6 single-column">
            <div class="single-item pt-4">
              <h6></h6>
              <router-link to="">
                <h4 style="">
                  <div class="clipe-paragrafo  text-uppercase">Biblioteca</div>
                </h4>
              </router-link>
            </div>
          </div>
-->



          <div class="col-lg-2 col-md-6 col-sm-6 col-6 single-column">
            <div class="single-item pt-4">
              <h6></h6>
              <h4>
                <div class="clipe-paragrafo text-uppercase" style="cursor: pointer;" onclick="window.location.href = 'https://bibvirtual.parlamento.ao/'">Biblioteca</div>
              </h4>
            </div>
          </div>





          <div class="col-lg-3 col-md-6 col-sm-6 col-6 single-column">
            <div class="single-item pt-4">
              <h6></h6>
              <router-link to="ORCAMENTO_GERAL_ESTADO">
                <h4 style="">
                  <div class="clipe-paragrafo text-uppercase">Orçamento Geral do Estado</div>
                </h4>
              </router-link>
            </div>
          </div>

          <div class="col-lg-2 col-md-6 col-sm-6 col-6 single-column">
            <div class="single-item pt-4">
              <h6></h6>
              <router-link to="multimedia">
                <h4 style="">
                  <div class="clipe-paragrafo text-uppercase">Multimédia</div>
                </h4>
              </router-link>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 col-sm-6 col-6  single-column">
            <div class="single-item pt-4">
              <h6></h6>
              <router-link to="agenda_parlamentar">
                <h4 style="">
                  <div class="clipe-paragrafo text-uppercase">Eventos</div>

                </h4>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
/*
function redirectToExternalRoute(route) {
  // Redireciona para a rota externa
  window.location.href = route;
}*/


/// script para link externo menu biblioteca
document.addEventListener('DOMContentLoaded', function() {
  const clipeParagrafo = document.querySelector('.clipe-paragrafo');
  clipeParagrafo.addEventListener('click', function() {
    window.location.href = 'https://bibvirtual.parlamento.ao/';
  });
});





/*
export default {
  name: "ActivitiesSection",
  components: {},

  props: {
    itens: []
  }
}
*/



</script>

<style scoped>
.clipe-paragrafo {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.auto-container h4 {
  font-size: 16px;
}
</style>

