<template>
  <div>
    <section class="sidebar-page-container sec-pad-2 pb-0 mb-0">
      <div class="auto-container">
        <div class="row clearfix">

          <div class="col-lg-8 col-md-12 col-sm-12 content-side border-right">
            <router-view :key="$route.path"></router-view>
          </div>

          <div class="col-lg-4 col-md-12 col-sm-12 sidebar-side ">
            <div class="blog-sidebar">

              <div class="sidebar-widget category-widget">

                <h3>Lista de Deputados </h3>
                <br>
                <div class="widget-content">
                  <ul>
                    <li v-for="deputy in this.visibleDeputies" :key="deputy.id">
                      <div class="row">
                        <span class="font-weight-bold mt-2" style="">{{ deputy.title }}</span>
                        <div class="col-sm-12 p-0 justify-content-center">
                          <img style="border-radius: 5px!important; max-height: 240px; width: 100%"
                               v-bind:src="this.dominT + deputy.imgCapa" :alt="deputy.title">
                        </div>
                        <div class="col-sm-12 p-0">
                          <br>
                          <div>

                            <router-link :to="'#'" class="cursor-pointe content-socials mb-2">
                              <i class="fab fa-facebook-f"></i>
                            </router-link>
                            <router-link :to="'#'" class="cursor-pointe content-socials mb-2">
                              <i class="fab fa-linkedin-in"></i>
                            </router-link>
                            <router-link :to="'#'" class="cursor-pointe content-socials mb-2">
                              <i class="fab fa-twitter"></i>
                            </router-link>
                            <router-link :to="'#'" class="cursor-pointe content-socials mb-2">
                              <i class="fab fa-instagram"></i>
                            </router-link>

                            <router-link :to="{name:'DeputyView', params: { Deputado: deputy.object_iuu } }"
                                         class="badge badge-danger font-weight-light p-2
                                          float-right cursor-pointe  mb-2">Ver detalhado
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>

                <paginate-components
                    v-bind:list="this.deputies"
                    v-on:page:update="updatePage"
                    v-bind:current-page="this.currentPage"
                    v-bind:page-size="this.pageSize"/>


              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="border-bottom"></div>
    </section>

    <section class="team-section alternat-2 sec-pad pb-140" style="background-color: rgba(11,30,44,0.06);">
      <div class="pattern-layer"></div>
      <div class="auto-container">
        <div class="sec-title centred text-left">
          <h2>Destaque dos Deputados</h2>
          <div class="title-shape float-left"></div>
        </div>
        <div class="four-item-carousel owl-carousel owl-theme owl-nav-none">
          <div class="team-block-one">
            <div class="inner-box">
              <figure class="image-box"><img src="assets/images/team/team-1.jpg" alt=""></figure>
              <div class="lower-content">
                <div class="author-box">
                  <h4><a href="index.html">Bertram Irvin</a></h4>
                  <span class="designation">President</span>
                </div>
                <ul class="othre-info clearfix">
                  <li class="mail-box"><a href="index.html"><i class="flaticon-mail-inbox-app"></i>Email</a></li>
                  <li class="phone-box"><i class="flaticon-emergency-call"></i><a href="tel:4488812345">+44 888 12
                    345</a></li>
                  <li class="share-option">
                    <i class="share-icon flaticon-share"></i>
                    <ul class="share-links clearfix">
                      <li><a href="index.html"><i class="fab fa-pinterest-p"></i></a></li>
                      <li><a href="index.html"><i class="fab fa-twitter"></i></a></li>
                      <li><a href="index.html"><i class="fab fa-facebook-f"></i></a></li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="team-block-one">
            <div class="inner-box">
              <figure class="image-box"><img src="assets/images/team/team-2.jpg" alt=""></figure>
              <div class="lower-content">
                <div class="author-box">
                  <h4><a href="index.html">Elvina Julie</a></h4>
                  <span class="designation">Actuary</span>
                </div>
                <ul class="othre-info clearfix">
                  <li class="mail-box"><a href="index.html"><i class="flaticon-mail-inbox-app"></i>Email</a></li>
                  <li class="phone-box"><i class="flaticon-emergency-call"></i><a href="tel:4488812345">+44 888 12
                    345</a></li>
                  <li class="share-option">
                    <i class="share-icon flaticon-share"></i>
                    <ul class="share-links clearfix">
                      <li><a href="index.html"><i class="fab fa-pinterest-p"></i></a></li>
                      <li><a href="index.html"><i class="fab fa-twitter"></i></a></li>
                      <li><a href="index.html"><i class="fab fa-facebook-f"></i></a></li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="team-block-one">
            <div class="inner-box">
              <figure class="image-box"><img src="assets/images/team/team-3.jpg" alt=""></figure>
              <div class="lower-content">
                <div class="author-box">
                  <h4><a href="index.html">Herman Gordon</a></h4>
                  <span class="designation">Director</span>
                </div>
                <ul class="othre-info clearfix">
                  <li class="mail-box"><a href="index.html"><i class="flaticon-mail-inbox-app"></i>Email</a></li>
                  <li class="phone-box"><i class="flaticon-emergency-call"></i><a href="tel:4488812345">+44 888 12
                    345</a></li>
                  <li class="share-option">
                    <i class="share-icon flaticon-share"></i>
                    <ul class="share-links clearfix">
                      <li><a href="index.html"><i class="fab fa-pinterest-p"></i></a></li>
                      <li><a href="index.html"><i class="fab fa-twitter"></i></a></li>
                      <li><a href="index.html"><i class="fab fa-facebook-f"></i></a></li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="team-block-one">
            <div class="inner-box">
              <figure class="image-box"><img src="assets/images/team/team-4.jpg" alt=""></figure>
              <div class="lower-content">
                <div class="author-box">
                  <h4><a href="index.html">Marian Lenora</a></h4>
                  <span class="designation">Speaker</span>
                </div>
                <ul class="othre-info clearfix">
                  <li class="mail-box"><a href="index.html"><i class="flaticon-mail-inbox-app"></i>Email</a></li>
                  <li class="phone-box"><i class="flaticon-emergency-call"></i><a href="tel:4488812345">+44 888 12
                    345</a></li>
                  <li class="share-option">
                    <i class="share-icon flaticon-share"></i>
                    <ul class="share-links clearfix">
                      <li><a href="index.html"><i class="fab fa-pinterest-p"></i></a></li>
                      <li><a href="index.html"><i class="fab fa-twitter"></i></a></li>
                      <li><a href="index.html"><i class="fab fa-facebook-f"></i></a></li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="team-block-one">
            <div class="inner-box">
              <figure class="image-box"><img src="assets/images/team/team-1.jpg" alt=""></figure>
              <div class="lower-content">
                <div class="author-box">
                  <h4><a href="index.html">Bertram Irvin</a></h4>
                  <span class="designation">President</span>
                </div>
                <ul class="othre-info clearfix">
                  <li class="mail-box"><a href="index.html"><i class="flaticon-mail-inbox-app"></i>Email</a></li>
                  <li class="phone-box"><i class="flaticon-emergency-call"></i><a href="tel:4488812345">+44 888 12
                    345</a></li>
                  <li class="share-option">
                    <i class="share-icon flaticon-share"></i>
                    <ul class="share-links clearfix">
                      <li><a href="index.html"><i class="fab fa-pinterest-p"></i></a></li>
                      <li><a href="index.html"><i class="fab fa-twitter"></i></a></li>
                      <li><a href="index.html"><i class="fab fa-facebook-f"></i></a></li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="team-block-one">
            <div class="inner-box">
              <figure class="image-box"><img src="assets/images/team/team-2.jpg" alt=""></figure>
              <div class="lower-content">
                <div class="author-box">
                  <h4><a href="index.html">Elvina Julie</a></h4>
                  <span class="designation">Actuary</span>
                </div>
                <ul class="othre-info clearfix">
                  <li class="mail-box"><a href="index.html"><i class="flaticon-mail-inbox-app"></i>Email</a></li>
                  <li class="phone-box"><i class="flaticon-emergency-call"></i><a href="tel:4488812345">+44 888 12
                    345</a></li>
                  <li class="share-option">
                    <i class="share-icon flaticon-share"></i>
                    <ul class="share-links clearfix">
                      <li><a href="index.html"><i class="fab fa-pinterest-p"></i></a></li>
                      <li><a href="index.html"><i class="fab fa-twitter"></i></a></li>
                      <li><a href="index.html"><i class="fab fa-facebook-f"></i></a></li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

import api from "../../../services/api";
import Util from "../../../store/util";
import router from "../../../router";
import PaginateComponents from "./component/PaginateComponents";

export default {
  name: "ListDeputy",
  data() {
    return {
      deputies: [],
      dominT: Util.Domine,
      nextId: 2,
      currentPage: 0,
      pageSize: 3,
      visibleDeputies: []
    }
  },
  components: {
    PaginateComponents
  },
  async created() {
    await api.get('/Entities').then(async resp => {
      this.deputies = await resp.data.data.entities;
      if (this.deputies && this.$route.path == '/Deputados') {
        router.push({name: 'DeputyView', params: {Deputado: this.deputies[0].object_iuu}});
      }

    })

    await this.updatedeVisibleDeputies()
  },
  methods: {

    updatePage(pageNumber) {
      this.currentPage = pageNumber;
      this.updatedeVisibleDeputies();
    },
    updatedeVisibleDeputies() {
      this.visibleDeputies = this.deputies.slice(this.currentPage * this.pageSize, (this.currentPage * this.pageSize) + this.pageSize)
      if (this.visibleDeputies.length === 0 && this.currentPage > 0) {
        this.updatePage(this.currentPage - 1);
      }
    }

  },
  mounted() {
    window.jQuery(document).ready((async function ($) {
      if ($('.owl-carousel').length) {
        $('.owl-carousel').owlCarousel({
          loop: true,
          margin: 0,
          nav: true,
          animateOut: 'fadeOut',
          animateIn: 'fadeIn',
          active: true,
          smartSpeed: 1000,
          autoplay: 6000,
          navText: ['<span class="fal fa-angle-left"></span>', '<span class="fal fa-angle-right"></span>'],
          responsive: {
            0: {
              items: 1
            },
            600: {
              items: 2
            },
            800: {
              items: 3
            },
            1024: {
              items: 4
            }
          }
        });
      }
    }))
  }
}
</script>

<style scoped>
.cursor-pointe {
  cursor: pointer;
}

.content-socials {
  background-color: #1b1e21;
  color: #fff;
  margin-right: 5px;
  padding: 5px 10px;
  border-radius: 100%;
}

@media (min-width: 920px) {
  .img-content {

    width: 340px; height: 240px; max-width: 340px; max-height: 240px;
  }
}
</style>
