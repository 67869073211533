<template>
  <footer class="main-footer">


    <div class="widget-section">
      <div class="auto-container">
        <div class="row clearfix">

          <footer-widget my-title="Grupos Parlamentares" :list-itens="schedulesData.gru_parliamentary"
                         class="col-6 col-sm-4"/>

          <!--<footer-widget my-title="Grupo Parlamentar Misto" :list-itens="schedulesData.gp_misto"
                         class="col-6 col-sm-4"/>-->

          <footer-widget my-title="Representações Parlamentares" :list-itens="schedulesData.outras"
                         class="col-6 col-sm-4"/>

          <footer-column :data-list="schedulesData.location" class="col-6 col-sm-4"/>


          <div class="col-lg-3 col-md-6 col-sm-12 footer-column col-6"
               :style="'background: url('+ this.imageLogo +'); background-position: center; background-repeat: no-repeat'">
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="auto-container">
        <div class="bottom-inner clearfix">
          <div class="copyright pull-left">
            <p>&copy; 2022 <a href="index.html">Assembleia Nacional.</a> todos os direitos reservados. </p>
          </div>
          <ul class="footer-nav clearfix pull-right">
            <li><a href="index.html">Parlamento Angolano</a></li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>

import FooterColumn from "@/components/subComponent/FooterColumn";
import FooterWidget from "@/components/subComponent/FooterWidget";
import cacheBrowser from "../store/cache";
import api from "../services/api";
import imageLogo from "../assets/Logotransparente.png"


export default {
  name: "MainFooter",
  components: {
    "footer-column": FooterColumn,
    "footer-widget": FooterWidget,
  }, data() {
    return {
      imageLogo: imageLogo
    }
  },
  props: {
    schedulesData: Object
  },
  async created() {
    await api.get('/MainHeader').then(async () => {
      await cacheBrowser.setCache()
    }).catch(() => {
      cacheBrowser.getCache()
      console.log("request network not found")
    });


  }
}
</script>

