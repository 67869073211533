<template>
  <!-- sidebar-page-container secção das noticias pagina principal home -->
  <section class="sidebar-page-container sec-pad-2">
    <div class="auto-container">
      <div class="row clearfix">
        <div class="col-lg-8 col-md-12 col-sm-12 content-side">
          <div class="blog-details-content">
            <div class="inner-box" v-if="data">

              <div :class="'text ' + (item.title==='Não foi inserido conteudo no titulo'?'d-none':'' )"
                   v-for="item in this.visibleInformation" :key="item.id">
                <!--  {{ item.link_files }} -->
                <h3>{{ item.title }} </h3>
                <div id="context-component">
                  <!-- por motivos de ocorencia de erro fizemos || .replace('-- wp:paragraph -->
                  <p v-html="item.context.substr(this.actualText - 1,this.limitText).replace('-- wp:paragraph -->', '') + (item.context.length > this.limitText?' ...':'')"
                     style=""></p>

                  <p v-if="this.limitText < item.context.length">

                    &nbsp;
                    <span class="text-primary text-uppercase" v-on:click="allText(item.context.length )"
                          style="cursor: pointer">Ler Tudo</span>
                  </p>

                  <div class="row">
                    <span class="col-4 text-center" v-for="itemA in item.link_files" :key="itemA.id">
                      <a class="text-danger text-" :href="itemA.path" target="_blank">
                        <i style="margin-top: 16px!important;" class="fa fa-file-pdf fa-7x"></i>
                            </a>
                       <p>{{ nomeTexto(itemA.path) }}</p>

                    </span>
                  </div>
                </div>
                <div class="author-box" v-if="item.banner">
                  <!--  v-for="or in item.banner" :key="or.id"-->
                  <div class="text">
                    <h4>{{ item.banner.auth }}</h4>
                    <p>{{ item.banner.context }}</p>
                  </div>
                </div>
                <br>
              </div>
            </div>
            <div class="auto-container" v-else>
              <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>

          <!--   initial paginate info       -->
          <div class="mt-3 mb-3" style="height: 30px" v-if="totalPages() > 0">
            <div class="pagination-wrapper float-right">
              <ul class="pagination clearfix" v-if="this.totalPages() > 1">
                <li v-if="showPreviousLink()" v-on:click="updatePage(currentPage - 1)">
                  <i class="far fa-angle-double-left cursor-pointe"></i>
                </li>
                <!--        current-->
                <li v-for="count in this.pageAdpter" :key="count" v-on:click="updatePage(count - 1)">
                  <a href="javascript:void(0)" :class="count=== (this.currentPage+1)?'current':''">
                    {{ count }}
                  </a>
                </li>
                <destak v-if="totalPages() > 6">
                  <li>
                    <a href="#">
                      ...
                    </a>

                  </li>
                </destak>
                <li v-if="showNextLink()" style="cursor: pointer" v-on:click="updatePage(currentPage + 1)">
                  <i class="far fa-angle-double-right cursor-pointe"></i>
                </li>
              </ul>
            </div>
          </div>
          <!--  final paginate info        -->
        </div>



        <!-- Menu de Sugestões Lateral-->
        <div class="col-lg-4 col-md-12 col-sm-12 sidebar-side d-none d-sm-flex">
          <div class="blog-sidebar">
            <div class="sidebar-widget category-widget">
              <h3>Sugestões</h3>
              <br>
              <div class="widget-content">
                <div class="row clearfix">
                  <div class="col-lg-12 col-md-6 col-sm-6 col-6   single-column">
                    <div class="single-item pt-4">
                      <h6></h6>
                      <router-link to="convocatoria">
                        <h4 style="">
                          <div class="clipe-paragrafo ">Convocatória</div>
                          <!--             -->
                        </h4>
                      </router-link>
                    </div>
                  </div>
                  <div class="col-12 border border-secondary mt-3"></div>

                  <div class="col-lg-12 col-md-6 col-sm-6 col-6 single-column">
                    <div class="single-item pt-4">
                      <h6></h6>
                      <router-link to="intervencao">
                        <h4 style="">
                          <div class="clipe-paragrafo  ">Intervenções</div>
                        </h4>
                      </router-link>
                    </div>
                  </div>
                  <div class="col-12 border border-secondary mt-3"></div>
                  <div class="col-lg-12 col-md-6 col-sm-6 col-6 single-column">
                    <div class="single-item pt-4">
                      <h6></h6>
                      <router-link to="multimedia">
                        <h4 style="">
                          <div class="clipe-paragrafo ">Multimédia</div>
                        </h4>
                      </router-link>
                    </div>
                  </div>
                  <div class="col-12 border border-secondary mt-3"></div>
                  <div class="col-lg-12 col-md-6 col-sm-6 col-6  single-column">
                    <div class="single-item pt-4">
                      <h6></h6>
                      <router-link to="agenda_parlamentar">
                        <h4 style="">
                          <div class="clipe-paragrafo ">Eventos</div>
                          <!--               -->
                        </h4>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>

              <br>
              <post-widget-noticies/>
              <br>
              <div class="col-12 border border-secondary mt-3"></div>

            </div>
          </div>
        </div>
      </div>
    </div>

  </section>
  <!-- sidebar-page-container end -->
</template>

<script>

import PostWidgetNoticies from "../../views/clients/components/PostWidgetNoticies";

export default {
  name: "SubContexto",
  props: {
    data: Object
  },
  data() {
    return {
      visibleInformation: [],
      nextId: 3,
      currentPage: 0,
      pageSize: 3,
      pageAdpter: 0,
      actualText: 3,
      limitText: 9000
    }
  },
  created() {
    this.updatedDataList();
  },
  methods: {

    nomeTexto(texto){

        let arrayString = texto.split("/")
        let ultimoValor = arrayString[arrayString.length - 1];
        let ultimoTexto = ultimoValor.split("_")
        return ultimoTexto[ ultimoTexto.length -1];
    },

    totalPages() {

      this.totalPageData = Math.ceil(this.list.length / this.pageSize);
      this.pageAdpter = Math.ceil(this.list.length / this.pageSize);

      if (this.totalPageData >= 5) {
        this.pageAdpter = 5
      }
      return Math.ceil(this.list.length / this.pageSize);
    },
    showPreviousLink() {
      return this.currentPage !== 0;
    },
    showNextLink() {
      return this.currentPage !== (this.totalPages() - 1);
    },
    updatePage(pageNumber) {
      this.currentPage = pageNumber;
      this.updatedeVisibleDeputies();
    },

    updatedDataList: function () {
      this.list = this.data;
      this.visibleInformation = this.data.slice(this.currentPage * this.pageSize, (this.currentPage * this.pageSize) + this.pageSize)
      if (this.visibleInformation.length === 0 && this.currentPage > 0) {
        this.updatePage(this.currentPage - 1);
      }
    },
    nextText: function (lenght) {

      if ((lenght - this.limitText) > this.actualText) {
        this.actualText += this.limitText;
      }

      console.log(this.actualText + ' - ' + lenght)
    },
    voltarText: function (lenght) {

      if ((this.actualText - this.limitText) >= 0)
        this.actualText -= this.limitText;

      console.log(this.actualText + ' - ' + lenght)
    },
    allText: function (lenght) {
      this.limitText = lenght;
    }
  },
  components: {
    PostWidgetNoticies,
  }
}
</script>

<style scoped>
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #0b1e2c;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }

  span {
    font-size: 8px;
  }

}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

#context-component li {
  list-style: square;
  margin-left: 30px;
  text-align: left;
  margin-bottom: 15px;
}
</style>
