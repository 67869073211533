<template>
  <sub-header header="Historico Parlamentar"/>
  <history data-infor="Historico do parlamento 2021"></history>
  <team-section/>
</template>

<script>

import History from "@/components/History";
import SubHeader from "@/components/subComponent/SubHeader";
import TeamSection from "@/components/TeamSection";

export default {
  name: "HistoricoParlamento",
  components: {
    TeamSection,
    "history": History,
    "sub-header": SubHeader
  },
  data() {
    return;
  }
}
</script>

<style scoped>

</style>
