<template>
  <span v-if="socialites">
      <span v-for="iso in socialites " :key="iso">
          <router-link :to="'#'" class="cursor-pointe content-socials mb-2">
            <i class="fab fa-facebook-f"></i>
          </router-link>
          <router-link :to="'#'" class="cursor-pointe content-socials mb-2">
            <i class="fab fa-linkedin-in"></i>
          </router-link>
          <router-link :to="'#'" class="cursor-pointe content-socials mb-2">
            <i class="fab fa-twitter"></i>
          </router-link>
          <router-link :to="'#'" class="cursor-pointe content-socials mb-2">
            <i class="fab fa-instagram"></i>
          </router-link>
        </span>
  </span>
</template>

<script>
export default {
  name: "socialite-info",
  props: {
    socialites: []
  }
}
</script>

<style scoped>

.cursor-pointe {
  cursor: pointer;
}

.content-socials {
  background-color: #1b1e21;
  color: #fff;
  margin-right: 5px;
  padding: 5px 10px;
  border-radius: 100%;
}
</style>
