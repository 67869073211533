/**
 *
 * @type {{getServerHost()}}
 * Esta função tem como hojectivo de capturar as configurações  que redireciona ao
 * servidor de conteudo
 *
 * auth: Munzambi Ntemo Miguel
 * date: 2022-09-24 Sabado 12:32
 */

import Config from "../../public/config/tsconfig.json"

const config = {

    getServerHost() {
        return Config.host
    }
}

export default config;
