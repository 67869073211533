<template>
  <div>
    <section class="events-list sec-pad-2">
      <div class="auto-container">
        <div class="event-list-content">

          <div class="schedule-inner" v-for="item in this.data" :key="item.id">
            <div class="schedule-block-three" v-if="item.typeMultimedia === 5">
              <div class="inner-box">
                <div class="inner">
                  <div class="schedule-date p-2">
                    <h2 class="p-2">26 <span class="year">January’2021</span><span class="symple">th</span></h2>
                  </div>
                  <div class="text">
                    <h3><a href="event-details.html">{{ item.titleContext }}</a></h3>
                    <p>{{ item.introdutionContext }}</p>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="pagination-wrapper centred">
          <ul class="pagination clearfix" style="float: right">
            <li><a href="event.html"><i class="far fa-angle-double-left"></i></a></li>
            <li><a href="event.html" class="current">1</a></li>
            <li><a href="event.html">2</a></li>
            <li><a href="event.html"><i class="far fa-angle-double-right"></i></a></li>
          </ul>
        </div>
      </div>
    </section>
  </div>
</template>

<script>


import MultimideaJSON from "../../../json/MultimediaJSON.json";
import Util from "../../../store/util";

export default {
  name: "AudioViews",
  data() {
    return {
      data: MultimideaJSON.data,
      dominio: Util.DomineTo
    }
  },
  created() {
    this.data = Util.Multimedia.data
  }
}
</script>

<style scoped>

</style>
