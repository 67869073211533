<!--


  Objective: Componente Criado para exibir listas em outros componentes
  Date: Luanda 2021 23 de Novembro

  Department: CIAN
  Group: CIAN Dev Team

-->


<template>
  <!--  <ul :class="stylesClass" >-->
  <li v-for="itemInterator in listLinks" :key="itemInterator.id" v-bind:class="itemInterator.class"
      data-toggle="tooltip" :title="itemInterator.toolTip" style="margin-right: 1px!important;">

    <router-link :to="(itemInterator.url?itemInterator.url:'#')">
      <i :class="itemInterator.icon"> </i>{{ itemInterator.context }}
    </router-link>

    <ul v-if="itemInterator.elements" v-bind:style="minWidth">
      <li v-for="item in itemInterator.elements" :key="item.id" v-bind:class="item.class">


        <router-link :to="'/'+(item.url?item.url:'#')" data-toggle="toolTip" :title="item.context"
                     v-if="item.url.substr(0, 4) != 'http'">
          <i :class="item.icon"></i>
          <div style="overflow: hidden; text-overflow: ellipsis; width:100%; border:1px; white-space:nowrap;">
            {{ item.context }}
          </div>
        </router-link>

        <a :href="'//'+(item.url?item.url.substr((item.url.indexOf('//') + 2), item.url.length):'#')"
           data-toggle="toolTip" :title="item.context" target="_blank"
           v-if="item.url.substr(0, 4) == 'http'">
          <i :class="item.icon"></i>
          <div style="overflow: hidden; text-overflow: ellipsis; width:100%; border:1px; white-space:nowrap;">
            {{ item.context }}
          </div>
        </a>

        <ul v-if="item.elements" style="min-width: 320px">
          <li v-for="itemSub in item.elements" :key="itemSub.id" >
            <router-link :to="(itemSub.url?itemSub.url:'#')" >{{ itemSub.context }}</router-link>
          </li>
        </ul>

      </li>

    </ul>
  </li>
  <!--  </ul>-->
</template>

<script>

//import $ from 'jquery'

export default {
  name: "UList",
  methods: {
    eventElement: function (envet) {
      console.log(envet);
    }
  },
  props: {
    stylesClass: String,
    dropDown: String,
    minWidth: String,
    listLinks: []
  },
  mounted() {

  }
}
</script>
