<template>
  <div>

    <section class="solutions-section ">
      <figure class="image-layer"><img :src="schedulesData.figure" alt=""></figure>
      <div class="pattern-box">
        <div :class="item.pattern" v-for="item in schedulesData.images" :key="item.id"
             :style="'background-image: url('+item.url+');'">
        </div>

      </div>
      <div class="auto-container">
        <div class="sec-title centred">
          <h2>{{ schedulesData.title }} </h2>
          <div class="title-shape"></div>
        </div>
        <div class="inner-container">
          <div class="upper-box clearfix">
            <solution-block-one :data-list="schedulesData.list_section"/>
          </div>
          <div class="lower-box clearfix">
            <div class="bg-layer"
                 v-bind:style="'background-image:url('+schedulesData.clearfix.url +');'">
            </div>
            <div class="text pull-left">
              <div class="icon-box"><i class="flaticon-idea"></i></div>
              <h3>{{ schedulesData.clearfix.h3 }}</h3>
              <p>{{ schedulesData.clearfix.p }}</p>
            </div>
            <div class="btn-box pull-right">
              <router-link to="/contacto/forms" class="theme-btn">{{ schedulesData.clearfix.btn }}</router-link>
            </div>
          </div>
        </div>
      </div>

    </section>
    <footer class="main-footer">
      <div class="footer-top">

      </div>
    </footer>
  </div>
</template>

<script>

import SolutionBlockOne from "@/components/subComponent/SolutionBlockOne";

export default {
  name: "SolutionsSection",
  components: {

    "solution-block-one": SolutionBlockOne
  },
  props: {
    schedulesData: Object
  },
}

</script>

<style scoped>

</style>
