<template>
  <div style="min-height:700px">

    <section class="events-list sec-pad-2 " v-if="this.noticias">
      <div class="auto-container ">
        <div class="event-list-content ">

          <div class="schedule-inner" v-for="item in this.visibleNoticie" :key="item.id">
            <div class="schedule-block-three">
              <div class="inner-box">
                <div class="inner">
                  <div class="schedule-date h-100"
                       :style="'background: url('+(item.details??(dominio + item.img))+'); background-repeat: no-repeat; background-position-y: 0px; background-size: 100% 100%; background-position-x: 40% '">
                    <!--
                      <h2>26 <span class="year">January’2021</span><span class="symple"></span>
                      </h2>
                    -->

                  </div>
                  <div class="text">

                    <h3><a href="#">{{ item.context }}</a></h3>
                    <p v-html="item.p || item.titleContext  " style="max-height: 70.9px; overflow: hidden;"></p>
                    <span class="category w-25"></span>
                    <div class="link">
                      <router-link :to="(item.src??( this.$route.fullPath.replace('/', '') +  '_'+item.id))"
                                   v-if="!item.src">Mais Informação<i
                          class="flaticon-right-arrow"></i>
                      </router-link>
                      <a target="_blank" :href="item.src" v-else>Mais Informação <i
                          class="flaticon-right-arrow"></i></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
        <div class="pagination-wrapper centred">
          <paginate-components
              v-bind:list="this.noticias"
              v-on:page:update="updatePage"
              v-bind:current-page="this.currentPage"
              v-bind:page-size="this.pageSize"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PaginateComponents from "@/views/clients/parlamento/component/PaginateComponents";
import Util from "@/store/util";

export default {
  name: "TodosNoticiasVideos",
  components: {
    PaginateComponents
  },
  props: {
    noticias: []
  },

  data() {
    return {
      visibleNoticie: [],
      nextId: 2,
      currentPage: 0,
      pageSize: 6,
      dominio: Util.Domine
    }
  }, created() {
    this.updatedVisible()
  },
  methods: {
    updatePage(pageNumber) {
      this.currentPage = pageNumber;
      this.updatedVisible();
    },
    updatedVisible() {
      this.visibleNoticie = this.noticias.slice(this.currentPage * this.pageSize, (this.currentPage * this.pageSize) + this.pageSize)
      if (this.visibleNoticie.length === 0 && this.currentPage > 0) {
        this.updatePage(this.currentPage - 1);
      }
    }
  }
}
</script>

<style scoped>

</style>