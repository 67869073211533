<template>
  <div style="background: #000000">
    <section class="page-title  min-height-sm-500 " v-if="data"
             :style="'background-image: linear-gradient(to bottom, rgba(0,0,0,0.2) 60%,rgba(0,0,0.4) 100%), url(' + (data.src??(this.domine + data.img)) + '); '+ this.posittion+'; background-position: center;'
             +this.sizePossition+' ;min-height: 550px!important;'+ this.sizeLeft +';'">
      <div class="auto-container" style="">
        <div class="content-box justify-content-cente" style="margin-top: 220px!important; ">
          <div class="title centred">
            <h1 v-if="data">{{ data.h1 }}</h1>
          </div>
        </div>
      </div>
    </section>
    <div v-else>
      <section class="page-title" style=""
               :style="'background-image: url();'">
        <div class="auto-container">
          <div class="content-box">
            <div class="title centred">
              <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>


  </div>
</template>

<script>

import Util from "../../store/util";

export default {
  name: "SubHeader",
  data() {
    return {
      domine: Util.Domine,
      posittion: 'background-position-y: top',
      sizePossition: '',
      sizeLeft: '',
      stylesInfors: ''
    }
  },
  mounted() {

    if (this.$route.fullPath.includes('Slider_')) {
      this.posittion = 'background-position-y: center';
      this.sizePossition = 'background-size: 100%';
      this.sizeLeft = 'background-position-x: center';
      this.stylesInfors = 'background-image: linear-gradient(to right, rgba(0,0,0,1) 60%,rgba(0,0,0,1) 100%), \n' + '';
    }
    //this.posittion = '';
  },
  props: {
    data: Object
  }
}
</script>

<style scoped>
.min-height-sm-500 {
  max-height: 550px !important;
}

@media (min-width: 920px) {
  .min-height-sm-500 {
    max-height: 550px !important;
  }
}


.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #0b1e2c;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
</style>
