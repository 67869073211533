<template>
  <div>
    <section class="events-grid sec-pad-2">
      <div class="auto-container">
        <div class="row clearfix">

          <div class="col-lg-4 col-md-6 col-sm-12 schedule-block" v-for="item in this.data" :key="item.id">
            <div class="schedule-block-one" v-if="item.typeMultimedia < 3 ">
              <div class="inner-box">
                <div class="image-box">
                  <figure class="image"><img src="../../../assets/schedule-2.jpg" alt=""></figure>
                  <div class="content-box">
                    <div class="post-date" v-if="item.created_at"><h3>28<span>Jan’21</span></h3></div>
                    <div class="text">
                      <h4><a href="event-details.html">{{ item.titleContext }}</a></h4>
                    </div>
                  </div>
                </div>
                <div class="lower-content">

                  <div class="links"><a href="event-details.html">Saiba Mais<i class="flaticon-right-arrow"></i></a>
                  </div>
                  <div class="share-box"><a href="index.html">Partilhe<i class="fas fa-share-alt"></i></a></div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  </div>
</template>

<script>


import MultimideaJSON from "../../../json/MultimediaJSON.json";
import Util from "../../../store/util";

export default {
  name: "VideoViews",
  data() {
    return {
      data: MultimideaJSON.data,
      dominio: Util.DomineTo
    }
  },
  created() {
    this.data = Util.Multimedia.data
  }
}
</script>

<style scoped>

</style>
