import jQuery from "jquery"
var WOW = window.WOW;
export default {
    mounted() {
        window.jQuery(document).ready((async function ($) {

            "use strict";

            //Hide Loading Box (Preloader)
            function handlePreloader() {
                if ($('.loader-wrap').length) {
                    $('.loader-wrap').delay(1000).fadeOut(500);
                }
            }

            if ($(".preloader-close").length) {
                $(".preloader-close").on("click", function () {
                    $('.loader-wrap').delay(200).fadeOut(500);
                })
            }

            //Update Header Style and Scroll to Top
            function headerStyle() {
                if ($('.main-header').length) {
                    var windowpos = $(window).scrollTop();
                    var siteHeader = $('.main-header');
                    var scrollLink = $('.scroll-top');
                    if (windowpos >= 110) {
                        siteHeader.addClass('fixed-header');
                        scrollLink.addClass('open');
                    } else {
                        siteHeader.removeClass('fixed-header');
                        scrollLink.removeClass('open');
                    }
                }
            }

            headerStyle();

            // Scroll to a Specific Div
            if ($('.scroll-to-target').length) {
                $(".scroll-to-target").on('click', function () {
                    var target = $(this).attr('data-target');
                    // animate
                    $('html, body').animate({
                        scrollTop: $(target).offset().top
                    }, 1000);

                });
            }

            // Elements Animation
            if ($('.wow').length) {
                var wow = new WOW({
                    mobile: false
                });
                wow.init();
            }

            //Contact Form Validation
            if ($('#contact-form').length) {
                $('#contact-form').validate({
                    rules: {
                        username: {
                            required: true
                        },
                        email: {
                            required: true,
                            email: true
                        },
                        phone: {
                            required: true
                        },
                        subject: {
                            required: true
                        },
                        message: {
                            required: true
                        }
                    }
                });
            }

            //Fact Counter + Text Count
            if ($('.count-box').length) {
                $('.count-box').appear(function () {

                    var $t = $(this),
                        n = $t.find(".count-text").attr("data-stop"),
                        r = parseInt($t.find(".count-text").attr("data-speed"), 10);

                    if (!$t.hasClass("counted")) {
                        $t.addClass("counted");
                        $({
                            countNum: $t.find(".count-text").text()
                        }).animate({
                            countNum: n
                        }, {
                            duration: r,
                            easing: "linear",
                            step: function () {
                                $t.find(".count-text").text(Math.floor(this.countNum));
                            },
                            complete: function () {
                                $t.find(".count-text").text(this.countNum);
                            }
                        });
                    }

                }, {accY: 0});
            }


            //LightBox / Fancybox
            if ($('.lightbox-image').length) {
                $('.lightbox-image').fancybox({
                    openEffect: 'fade',
                    closeEffect: 'fade',
                    helpers: {
                        media: {}
                    }
                });
            }


            //Tabs Box
            if ($('.tabs-box').length) {
                $('.tabs-box .tab-buttons .tab-btn').on('click', function (e) {
                    e.preventDefault();
                    var target = $($(this).attr('data-tab'));

                    if ($(target).is(':visible')) {
                        return false;
                    } else {
                        target.parents('.tabs-box').find('.tab-buttons').find('.tab-btn').removeClass('active-btn');
                        $(this).addClass('active-btn');
                        target.parents('.tabs-box').find('.tabs-content').find('.tab').fadeOut(0);
                        target.parents('.tabs-box').find('.tabs-content').find('.tab').removeClass('active-tab');
                        $(target).fadeIn(300);
                        $(target).addClass('active-tab');
                    }
                });
            }


            //Accordion Box
            if ($('.accordion-box').length) {
                $(".accordion-box").on('click', '.acc-btn', function () {

                    var outerBox = $(this).parents('.accordion-box');
                    var target = $(this).parents('.accordion');

                    if ($(this).hasClass('active') !== true) {
                        $(outerBox).find('.accordion .acc-btn').removeClass('active');
                    }

                    if ($(this).next('.acc-content').is(':visible')) {
                        return false;
                    } else {
                        $(this).addClass('active');
                        $(outerBox).children('.accordion').removeClass('active-block');
                        $(outerBox).find('.accordion').children('.acc-content').slideUp(300);
                        target.addClass('active-block');
                        $(this).next('.acc-content').slideDown(300);
                    }
                });
            }


            // banner-carousel
            if ($('.banner-carousel').length) {
                $('.banner-carousel').owlCarousel({
                    loop: true,
                    margin: 0,
                    nav: true,
                    animateOut: 'fadeOut',
                    animateIn: 'fadeIn',
                    active: true,
                    smartSpeed: 1000,
                    autoplay: 6000,
                    navText: ['<span class="fal fa-angle-left"></span>', '<span class="fal fa-angle-right"></span>'],
                    responsive: {
                        0: {
                            items: 1
                        },
                        600: {
                            items: 1
                        },
                        800: {
                            items: 1
                        },
                        1024: {
                            items: 1
                        }
                    }
                });
            }


            // news-carousel
            if ($('.news-carousel').length) {
                $('.news-carousel').owlCarousel({
                    loop: true,
                    margin: 0,
                    nav: true,
                    animateOut: 'fadeOut',
                    animateIn: 'fadeIn',
                    active: true,
                    smartSpeed: 1000,
                    autoplay: 5000,
                    navText: ['<span class="fal fa-angle-left"></span>', '<span class="fal fa-angle-right"></span>'],
                    responsive: {
                        0: {
                            items: 1
                        },
                        600: {
                            items: 1
                        },
                        800: {
                            items: 1
                        },
                        1024: {
                            items: 1
                        }
                    }
                });
            }



            //three-item-carousel
            if ($('.three-item-carousel').length) {
                $('.three-item-carousel').owlCarousel({
                    loop: true,
                    margin: 30,
                    nav: true,
                    smartSpeed: 1000,
                    autoplay: 500,
                    navText: ['<span class="far fa-long-arrow-alt-left"></span>', '<span class="far fa-long-arrow-alt-right"></span>'],
                    responsive: {
                        0: {
                            items: 1
                        },
                        480: {
                            items: 1
                        },
                        600: {
                            items: 2
                        },
                        800: {
                            items: 2
                        },
                        1024: {
                            items: 3
                        }
                    }
                });
            }


            // Four Item Carousel
            if ($('.four-item-carousel').length) {
                $('.four-item-carousel').owlCarousel({
                    loop: true,
                    margin: 30,
                    nav: true,
                    smartSpeed: 500,
                    autoplay: 5000,
                    navText: ['<span class="fas fa-angle-left"></span>', '<span class="fas fa-angle-right"></span>'],
                    responsive: {
                        0: {
                            items: 1
                        },
                        600: {
                            items: 2
                        },
                        800: {
                            items: 2
                        },
                        1024: {
                            items: 3
                        },
                        1200: {
                            items: 4
                        }
                    }
                });
            }


            // single-item-carousel
            if ($('.single-item-carousel').length) {
                $('.single-item-carousel').owlCarousel({
                    loop: true,
                    margin: 30,
                    nav: false,
                    smartSpeed: 3000,
                    autoplay: true,
                    navText: ['<span class="fal fa-angle-left"></span>', '<span class="fal fa-angle-right"></span>'],
                    responsive: {
                        0: {
                            items: 1
                        },
                        480: {
                            items: 1
                        },
                        600: {
                            items: 1
                        },
                        800: {
                            items: 1
                        },
                        1200: {
                            items: 1
                        }

                    }
                });
            }

            // twitter-carousel
            if ($('.twitter-carousel').length) {
                $('.twitter-carousel').owlCarousel({
                    loop: true,
                    margin: 30,
                    nav: false,
                    smartSpeed: 3000,
                    autoplay: true,
                    navText: ['<span class="fas fa-angle-left"></span>', '<span class="fas fa-angle-right"></span>'],
                    responsive: {
                        0: {
                            items: 1
                        },
                        480: {
                            items: 1
                        },
                        600: {
                            items: 1
                        },
                        800: {
                            items: 1
                        },
                        1200: {
                            items: 1
                        }

                    }
                });
            }

            // bx-slider
            if ($('.schedule-carousel').length) {
                $('.schedule-carousel').bxSlider({

                    auto: true,
                    mode: 'vertical',
                    nextText: '<i class="fal fa-angle-up"></i>',
                    prevText: '<i class="fal fa-angle-down"></i>',
                    maxSlides: 1,
                    minSlides: 1,
                    moveSlides: 1,
                    pause: 5000,
                    speed: 700,
                    pager: true,
                });
            }

            //schedule Tabs


            //Add One Page nav
            if ($('.scroll-nav').length) {
                $('.scroll-nav').onePageNav();
            }


            $(document).ready(function () {
                //$('select:not(.ignore)').niceSelect();
            });


            //Sortable Masonary with Filters
            function enableMasonry() {
                if ($('.sortable-masonry').length) {

                    var winDow = $(window);
                    // Needed variables
                    var $container = $('.sortable-masonry .items-container');
                    var $filter = $('.filter-btns');

                    $container.isotope({
                        filter: '*',
                        masonry: {
                            columnWidth: '.masonry-item.small-column'
                        },
                        animationOptions: {
                            duration: 500,
                            easing: 'linear'
                        }
                    });


                    // Isotope Filter
                    $filter.find('li').on('click', function () {
                        var selector = $(this).attr('data-filter');

                        try {
                            $container.isotope({
                                filter: selector,
                                animationOptions: {
                                    duration: 500,
                                    easing: 'linear',
                                    queue: false
                                }
                            });
                        } catch (err) {
                            return false;
                        }
                        return false;
                    });


                    winDow.on('resize', function () {
                        var selector = $filter.find('li.active').attr('data-filter');

                        $container.isotope({
                            filter: selector,
                            animationOptions: {
                                duration: 500,
                                easing: 'linear',
                                queue: false
                            }
                        });
                    });


                    var filterItemA = $('.filter-btns li');

                    filterItemA.on('click', function () {
                        var $this = $(this);
                        if (!$this.hasClass('active')) {
                            filterItemA.removeClass('active');
                            $this.addClass('active');
                        }
                    });
                }
            }

            enableMasonry();

            // bx-slider
            if ($('.hostory-slide').length) {
                $('.hostory-slide').bxSlider({

                    auto: true,
                    mode: 'vertical',
                    nextText: '<i class="fal fa-angle-up"></i>',
                    prevText: '<i class="fal fa-angle-down"></i>',
                    maxSlides: 3,
                    minSlides: 3,
                    moveSlides: 1,
                    pause: 5000,
                    speed: 700,
                    pager: false,
                });
            }

            if ($('.testimonial-style-two .bxslider').length) {
                $('.testimonial-style-two .bxslider').bxSlider({
                    nextText: '<i class="fal fa-angle-right"></i>',
                    prevText: '<i class="fal fa-angle-left"></i>',
                    mode: 'fade',
                    auto: 'true',
                    speed: '700',
                    pagerCustom: '.testimonial-style-two .slider-pager .thumb-box'
                });
            }
            //Jquery Spinner / Quantity Spinner
            if ($('.quantity-spinner').length) {
                $("input.quantity-spinner").TouchSpin({
                    verticalbuttons: true
                });
            }


            //Search Popup
            if ($('#search-popup').length) {

                //Show Popup
                $('.search-toggler').on('click', function () {
                    $('#search-popup').addClass('popup-visible');
                });
                $(document).keydown(function (e) {
                    if (e.keyCode === 27) {
                        $('#search-popup').removeClass('popup-visible');
                    }
                });
                //Hide Popup
                $('.close-search,.search-popup .overlay-layer').on('click', function () {
                    $('#search-popup').removeClass('popup-visible');
                });
            }


            // Progress Bar
            if ($('.count-bar').length) {
                $('.count-bar').appear(function () {
                    var el = $(this);
                    var percent = el.data('percent');
                    $(el).css('width', percent).addClass('counted');
                }, {accY: -50});

            }


            if ($('.timer').length) {
                $(function () {
                    $('[data-countdown]').each(function () {
                        var $this = $(this), finalDate = $(this).data('countdown');
                        $this.countdown(finalDate, function (event) {
                            $this.html(event.strftime('%D days %H:%M:%S'));
                        });
                    });
                });

                /*$('.cs-countdown').countdown('').on('update.countdown', function (event) {
                    var $this = $(this).html(event.strftime('<div class="count-col"><span>%D</span><h6>days</h6></div> <div class="count-col"><span>%H</span><h6>Hours</h6></div> <div class="count-col"><span>%M</span><h6>Minutes</h6></div> <div class="count-col"><span>%S</span><h6>Seconds</h6></div>'));
                });*/
            }


            // page direction
            function directionswitch() {
                if ($('.page_direction').length) {

                    $('.direction_switch button').on('click', function () {
                        $('body').toggleClass(function () {
                            return $(this).is('.rtl, .ltr') ? 'rtl ltr' : 'rtl';
                        })
                    });
                }

            }


            // Date picker
            function datepicker() {
                if ($('#datepicker').length) {
                    $('#datepicker').datepicker();
                }

            }

            /*	=========================================================================
            When document is Scrollig, do
            ========================================================================== */

            jQuery(document).on('ready', function () {
                (function () {
                    // add your functions
                    directionswitch();
                    datepicker();
                })(jQuery);
            });


            /* ==========================================================================
           When document is Scrollig, do
           ========================================================================== */

            $(window).on('scroll', function () {
                headerStyle();
            });


            /* ==========================================================================
           When document is loaded, do
           ========================================================================== */

            $(window).on('load', function () {
                handlePreloader();
                enableMasonry();
            });


        }))
    }
}