<template>
  <nav class="main-menu navbar-expand-md navbar-light" style="">
    <div>
      <div style="position: absolute; left: -20px; top: 10px; ">
        <a href="/"><img src="../../assets/LogoAN.jpeg" alt="" style="width: 90px; border-radius: 100%">
        </a>
      </div>

    </div>
    <div class="collapse navbar-collapse show clearfix " id="navbarSupportedContent" style="">
      <ul class="navigation clearfix" v-if="this.data" >
        <li-config :list-links="this.data.mainMenu" min-width="min-width: 300px"/>
      </ul>
    </div>
  </nav>
</template>

<script>

import LiList from "./LiList";


export default {
  name: "MainMenuView",
  components: {
    "li-config": LiList

  },
  props: {
    data: []
  }

}
</script>

<style scoped>
.circle-sm {
  position: absolute;
  width: 130px;

  height: 130px;
  background-color: #ffffff;
  top: -15px;
  border-radius: 100%;
  left: -40px;
  z-index: -1
}

.retangle-sm {
  position: absolute;
  width: 100px;
  height: 115.3px;
  border-radius: 10px;
  background-color: #ffffff;
  top: -15px;
  left: 50px;
  z-index: -1
}
.nav-menu-mobile li{
  font-size: 10px!important;
}
</style>
