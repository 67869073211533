<template>
  <div>
    <section class="sidebar-page-container sec-pad-2 pb-0 mb-0">
      <div class="auto-container">
        <div v-if="this.infoDeputy">
          <div class="row">
            <div class="row clearfix col-lg-8 col-12">
              <div class="col-lg-12 col-md-12 col-sm-12 p-0 m-0">
                <div class="m-0 col-lg-6 col-md-12 col-sm-12 image-column">
                  <div class="image_block_1">
                    <div class="image-box">
                      <figure class="image" v-if="this.infoDeputy.imgCapa">
                        <img :src="this.domine + this.infoDeputy.imgCapa" alt="" width="100%">
                      </figure>
                      <figure class="image" v-else>
                        <img src="/assets/images/resource/about-1.jpg" alt=""></figure>
                      <div class="text">
                        <h4>{{ this.infoDeputy.title }}</h4>
                        <p v-if="this.infoDeputy.departamentoName">{{ this.infoDeputy.departamentoName }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row justify-content-center p-4">
                <div class="col-lg-12 col-md-6 col-sm-6 big-column text-column">
                  <div class="text">
                    <div v-if="this.infoDeputy.context">
                      <p style="white-space: pre-line" v-html="this.infoDeputy.context"></p>
                      <div class="inner-box clearfix mb-3 mt-3">
                        <ul class="social-style-one clearfix">
                          <li><a href="index.html"><i class="fab fa-facebook-f"></i></a></li>
                          <li><a href="index.html"><i class="fab fa-twitter"></i></a></li>
                          <li><a href="index.html"><i class="fab fa-google-plus-g"></i></a></li>
                          <li><a href="index.html"><i class="fab fa-linkedin-in"></i></a></li>
                        </ul>
                      </div>
                    </div>
                    <div v-else>
                      <div class="lds-ellipsis">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>

                    </div>

                  </div>
                </div>
              </div>
            </div>
            <!-- Sugestões na parte lateral do perfil dos deputados  -->
            <div class="col-lg-4 col-md-12 col-sm-12 sidebar-side d-none d-sm-block">
              <div class="blog-sidebar">
                <div class="sidebar-widget category-widget">
                  <h3>Sugestões</h3>
                  <br>
                  <div class="widget-content">
                    <div class="row clearfix">
                      <div class="col-lg-12 col-md-6 col-sm-6 col-6   single-column">
                        <div class="single-item pt-4">
                          <h6></h6>
                          <router-link to="convocatoria">
                            <h4 style="">
                              <div class="clipe-paragrafo ">Convocatória</div>
                              <!--      -->
                            </h4>
                          </router-link>
                        </div>
                      </div>
                      <div class="col-12 border border-secondary mt-3"></div>

                      <div class="col-lg-12 col-md-6 col-sm-6 col-6 single-column">
                        <div class="single-item pt-4">
                          <h6></h6>
                          <router-link to="intervencao">
                            <h4 style="">
                              <div class="clipe-paragrafo  ">Intervenções</div>
                            </h4>
                          </router-link>
                        </div>
                      </div>
                      <div class="col-12 border border-secondary mt-3"></div>
                      <div class="col-lg-12 col-md-6 col-sm-6 col-6 single-column">
                        <div class="single-item pt-4">
                          <h6></h6>
                          <router-link to="multimedia">
                            <h4 style="">
                              <div class="clipe-paragrafo ">Multimédia</div>
                            </h4>
                          </router-link>
                        </div>
                      </div>
                      <div class="col-12 border border-secondary mt-3"></div>
                      <div class="col-lg-12 col-md-6 col-sm-6 col-6  single-column">
                        <div class="single-item pt-4">
                          <h6></h6>
                          <router-link to="agenda_parlamentar">
                            <h4 style="">
                              <div class="clipe-paragrafo ">Eventos</div>
                              <!--             -->
                            </h4>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br>
                  <!--  Noticias e destaques do parlamento  -->
                  <post-widget-noticies/>
                  <!-- Fim das notícias e destaques do parlamento -->
                  <br>
                  <br>
                  <div class="col-12 border border-secondary mt-3"></div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
<!--Mostrar deputados da mesma Legislatura na parte inferior no perfil do deputado-->
    <section class="sidebar-page-container sec-pad-2 pb-0 mb-0 d-none d-sm-block p-0">
      <div class="auto-container p-0">
        <div class="row clearfix">

          <div class="col-lg-12 col-md-12 col-sm-12 sidebar-side p-0">
            <div class="blog-sidebar">
              <div class="sidebar-widget category-widget" v-if="this.visibleDeputiesListando">

                <h3 v-if="this.visibleDeputiesListando[0].category">{{
                    this.visibleDeputiesListando[0].category.titleCapa
                  }} </h3>
                <h3 v-else>Deputados da Mesma Legislatura </h3>

                <br>
                <div class="widget-content">
                  <ul class="row">
                    <li class="col-12 col-sm-2" v-for="deputy in this.visibleDeputiesListando" :key="deputy.id">
                      <div class="">
                        <span class="font-weight-bold mt-2" style="">{{
                            deputy.title.substring(0, 22) + (deputy.title.length > 22 ? '...' : '')
                          }}</span>
                        <div class="col-sm-12 p-0 justify-content-center py-2">
                          <img style="border-radius: 5px!important; width: 100%"
                               v-bind:src="this.dominT + deputy.imgCapa" :alt="deputy.title">
                        </div>
                        <div class="col-sm-12 p-0">
                          <br>
                          <div>

                            <router-link :to="'#'" class="cursor-pointe content-socials mb-2">
                              <i class="fab fa-facebook-f"></i>
                            </router-link>
                            <router-link :to="'#'" class="cursor-pointe content-socials mb-2">
                              <i class="fab fa-linkedin-in"></i>
                            </router-link>
                            <router-link :to="'#'" class="cursor-pointe content-socials mb-2">
                              <i class="fab fa-twitter"></i>
                            </router-link>
                            <router-link :to="'#'" class="cursor-pointe content-socials mb-2">
                              <i class="fab fa-instagram"></i>
                            </router-link>

                            <router-link :to="{name:'ProfileDeputyInfo', params: { deputyprofile: deputy.object_iuu } }"
                                         class="badge badge-danger font-weight-light p-2
                                          float-right cursor-pointe  mb-2">Ver
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="border-bottom"></div>
    </section>

  </div>
</template>

<script>

import api from "../../../services/api";
import Util from "../../../store/util";
import PostWidgetNoticies from "./PostWidgetNoticies";

export default {
  name: "ProfileDeputyInfo",
  components: {
    PostWidgetNoticies
  },
  data() {
    return {
      infoDeputy: Object,
      domine: Util.Domine,
      dominT: Util.Domine,
      nextId: 2,
      currentPage: 0,
      pageSize: 6,
      visibleDeputiesListando: [],
      dataList: []
    }
  },
  async created() {
    try {
      await this.requesti()

    } catch (e) {
      console.log(e)
    }

  },
  methods: {
    requesti: function () {
      try {
        let URL = this.$route.path
        api.get(URL).then(resp => {

          this.infoDeputy = resp.data.data.entity

          api.get(this.infoDeputy.localkeyMenu).then(async resposta => {
            this.dataList = await resposta.data.data;
            await this.updatedeVisibleDeputiesInterno()
          })

        })


      } catch (e) {
        console.log(e)
      }
    },
    updatePageInterno(pageNumber) {
      this.currentPage = pageNumber;
      this.updatedeVisibleDeputiesInterno();
    },
    updatedeVisibleDeputiesInterno() {
      this.visibleDeputiesListando = this.dataList.slice(this.currentPage * this.pageSize, (this.currentPage * this.pageSize) + this.pageSize)
      if (this.visibleDeputiesListando.length === 0 && this.currentPage > 0) {
        this.updatePageInterno(this.currentPage - 1);
      }
    }

  },
}
</script>

<style scoped>
.cursor-pointe {
  cursor: pointer;
}

.content-socials {
  background-color: #1b1e21;
  color: #fff;
  margin-right: 5px;
  padding: 5px 10px;
  border-radius: 100%;
}

@media (min-width: 920px) {
  .img-content {

    width: 340px;
    height: 240px;
    max-width: 340px;
    max-height: 240px;
  }
}
</style>
