<template>
  <div class="boxed_wrapper">
    <main-header/>
    <router-view :key="$route.path"/>
    <main-footer :schedules-data="data.mainFooter"/>
    <button class="scroll-top scroll-to-target" data-target="html">
      <span class="fas fa-angle-up"></span>
    </button>
    <js_main/>
  </div>

</template>

<script>

// entrega objectos obstratos munzambi
import JSMain from "./external/jsmain";
import MainHeader from "./components/MainHeader";
import MainFooter from "./components/MainFooter";
import Util from "./store/util"
import MainFooterJson from "./json/MainFooter.json"
import api from "@/services/api";


export default {
  components: {
    "js_main": JSMain,
    'main-header': MainHeader,
    'main-footer': MainFooter
  },
  data() {
    return {
      data: MainFooterJson,
      title: String,
      context: String
    };
  },
  async created() {

    await api.get('/Notificacao', {
      proxy: false
    }).then(async responce => {

      this.title = responce.data.data.title
      this.context = responce.data.data.context
      this.notifyMe((responce.data.data.title??'Bem vindo no Portal do Parlamento angola'), responce.data.data.context)

    })


    await Notification.requestPermission();


    this.data = Util.FooterMain
  },
  methods: {


    notifyMe: function ($titles, $context) {
      if (Notification.permission !== "granted")
        Notification.requestPermission();
      else {
        var notification = new Notification($titles, {
          icon: 'assets/images/favicon.ico',
          body: $context,
        });
        notification.onclick = function () {
          window.open("https://parlamento.ao/");
        };
      }
    }
  },
  mounted() {

    /*document.addEventListener('DOMContentLoaded', function () {
      if (!Notification) {
        alert('Active as notificações para poder receber as actualizações da A.N.'); //if browser is not compatible this will occur
        return;
      }
      if (Notification.permission !== "granted")
        Notification.requestPermission();
    });*/


    window.addEventListener('load', () => {
      registerSW();
    });

    async function registerSW() {
      if ('serviceWorker' in navigator) {
        try {
          await navigator.serviceWorker
              .register('./sw.js')
              .then((e) => {
                console.log('Success ', e.scope)
              });
        } catch (e) {
          console.log(`SW registration failed`);
        }
      }
    }

  }
}


</script>
<style scoped>
@import url("https://fonts.googleapis.com/css?family=Roboto");

@-webkit-keyframes come-in {
  0% {
    -webkit-transform: translatey(100px);
    transform: translatey(100px);
    opacity: 0;
  }
  30% {
    -webkit-transform: translateX(-50px) scale(0.4);
    transform: translateX(-50px) scale(0.4);
  }
  70% {
    -webkit-transform: translateX(0px) scale(1.2);
    transform: translateX(0px) scale(1.2);
  }
  100% {
    -webkit-transform: translatey(0px) scale(1);
    transform: translatey(0px) scale(1);
    opacity: 1;
  }
}

@keyframes come-in {
  0% {
    -webkit-transform: translatey(100px);
    transform: translatey(100px);
    opacity: 0;
  }
  30% {
    -webkit-transform: translateX(-50px) scale(0.4);
    transform: translateX(-50px) scale(0.4);
  }
  70% {
    -webkit-transform: translateX(0px) scale(1.2);
    transform: translateX(0px) scale(1.2);
  }
  100% {
    -webkit-transform: translatey(0px) scale(1);
    transform: translatey(0px) scale(1);
    opacity: 1;
  }
}


.floating-container {
  position: fixed;
  width: 100px;
  height: 100px;
  bottom: 10px;
  right: 60px;
  margin: 35px 25px;
}

.floating-container:hover {
  height: 300px;
}

.floating-container .floating-button {
  box-shadow: 0 10px 25px rgb(52, 58, 64);
  -webkit-transform: translatey(5px);
  transform: translatey(5px);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.floating-container:hover .element-container .float-element:nth-child(1) {
  -webkit-animation: come-in 0.4s forwards 0.2s;
  animation: come-in 0.4s forwards 0.2s;
}

.floating-container:hover .element-container .float-element:nth-child(2) {
  -webkit-animation: come-in 0.4s forwards 0.4s;
  animation: come-in 0.4s forwards 0.4s;
}

.floating-container:hover .element-container .float-element:nth-child(3) {
  -webkit-animation: come-in 0.4s forwards 0.6s;
  animation: come-in 0.4s forwards 0.6s;
}

.floating-container .floating-button {
  position: absolute;
  width: 65px;
  height: 65px;
  background: #020a0e;
  bottom: 0;
  border-radius: 50%;
  left: 0;
  right: 0;
  margin: auto;
  color: white;
  line-height: 65px;
  text-align: center;
  font-size: 23px;
  z-index: 100;
  box-shadow: 0 10px 25px -5px rgba(44, 179, 240, 0.6);
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.floating-container .float-element {
  position: relative;
  display: block;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin: 15px auto;
  color: white;
  font-weight: 500;
  text-align: center;
  line-height: 50px;
  z-index: 0;
  opacity: 0;
  -webkit-transform: translateY(100px);
  transform: translateY(100px);
}

.floating-container .float-element .material-icons {
  vertical-align: middle;
  font-size: 16px;
}

.floating-container .float-element:nth-child(1) {
  background: #040d10;
  box-shadow: 0 20px 20px -10px rgba(66, 165, 245, 0.5);
}

.floating-container .float-element:nth-child(2) {
  background: #4CAF50;
  box-shadow: 0 20px 20px -10px rgba(76, 175, 80, 0.5);
}

.floating-container .float-element:nth-child(3) {
  background: #FF9800;
  box-shadow: 0 20px 20px -10px rgba(255, 152, 0, 0.5);
}
</style>

