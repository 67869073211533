<template>
  <div v-if="data">
    <section class="sidebar-page-container sec-pad-2 bg-color-1">
      <div class="auto-container">
        <div class="row clearfix">
          <div class="col-lg-12 col-md-12 col-sm-12 content-side">

            <div class="content-side mb-4 bg-white p-2">
              <router-link to="noticia" class="badge badge-danger p-2">Todas as Notícias
              </router-link>
              &nbsp;
              <router-link to="videos" class="badge badge-warning p-2">Todos os Videos
              </router-link>
            </div>

            <div class="blog-details-content ">
              <div class="row">
                <div class="col-12 col-lg-4 col-md-6 col-sm-6 content-side bg-white  "
                     v-for="data in this.visibleNoticie" :key="data.id"
                     style="border-radius: 10px">
                  <div class="inner-box">
                    <figure class="image-box p-0" style="max-height: 180px; min-height:180px;">
                      <img :src="dominio+ data.img" style="max-height: 180px; min-height:180px;"
                           alt=""></figure>
                    <h4 class="" style="min-height: 60px!important;">
                      <span data-tooltip="{{ data.context }}" data-flow="right">{{
                          data.context.substr(0, 50) + (data.context.length > 54 ? ' ...' : ' ')
                        }}</span>
                    </h4>
                    <br>
                    <div class="text"
                         style="height: 170px!important; text-overflow: ellipsis;  overflow: hidden; ">
                      <p style="background-color: rgb(248,248,248); border-radius: 5px; padding: 10px;  color: #000000!important;"
                         v-html="data.destaque.substr(0,200)+' ...'"></p>

                    </div>
                    <div class="btn-box mt-4 mb-2" style="float: right">
                      <router-link :to="'Noticia_'+ data.id" class="badge badge-danger p-2">Mais informação
                      </router-link>
                    </div>
                    <div class="download-block-one row" v-if="data.anexolists">

                      <a :href="this.dominio + element.path " v-for="element in data.anexolists" :key="element.id"
                         download>
                        <figure class="icon-box mr-2 mt-3" style="cursor: pointer">
                          <img src="/assets/images/icons/icon-1.png" style="width: 36px" alt="">
                        </figure>
                      </a>
                    </div>

                  </div>
                </div>

                <div class="col-12 col-lg-12 col-md-12 col-sm-12 content-side bg-white border-top mb-4">
                  <paginate-components
                      v-bind:list="this.noticiaInfo"
                      v-on:page:update="updatePage"
                      v-bind:current-page="this.currentPage"
                      v-bind:page-size="this.pageSize"/>
                </div>
              </div>

            </div>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12 sidebar-side" v-if="!this.hidenVideo">
            <div class="blog-sidebar">
              <div class="sidebar-widget category-widget mt-4">
                <div class="widget-title">
                  <h3>Videos <!--{{ data.categories.title }}--></h3>
                </div>
                <br>

                <div class="widget-content p-2 row">
                  <div class="col-sm-9 col-12 p-1" v-if="this.selectVideo">
                    <iframe width="100%" style="border-radius: 10px"
                            :src="this.selectVideo" class="iframe-content"
                            title="YouTube video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                  </div>
                  <div class="col-sm-3 col-12 row justify-content-center ml-1 p-0" id="listvideo-youtube">
                    <div v-for="item in this.visibleVideo"
                         :key="item.id" class="col-sm-12 col-4 m-0 p-1 " disabled>

                      <div class="col-lg-12 col-md-12 col-sm-12 explore-block mb-3" v-on:click="clickedIFram(item)">
                        <div class="explore-block-one wow fadeInUp animated animated" data-wow-delay="00ms"
                             data-wow-duration="1500ms">
                          <div class="inner-box">
                            <figure class="image-box"><img :src="item.details" alt="">
                            </figure>
                            <div class="content-box">
                              <div class="overlay-content position-absolute internal-style">
                                <p>{{ item.title }} <br>
                                  <a href="#sessao-apresentacao" class="badge badge-light p-2"
                                     v-on:click="clickedIFram(item)">Assistir</a>
                                </p>


                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                    </div>
                  </div>
                </div>

                <paginate-components
                    v-if="this.videoInfo.body_header"
                    v-bind:list="this.videoInfo.body_header"
                    v-on:page:update="updatePageVideo"
                    v-bind:current-page="this.currentPageVideo"
                    v-bind:page-size="this.pageSizeVideo"/>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Util from "../store/util";
import api from "../services/api";
import PaginateComponents from "../views/clients/parlamento/component/PaginateComponents";
import storage from "../store";

export default {
  name: "BlogPage",
  props: {
    data: Object,
    dataVideo: Object,
    hidenVideo: Boolean
  },
  data() {
    return {
      dominio: Util.Domine,
      noticiaInfo: [],
      videoInfo: [],
      nextId: 2,
      currentPage: 0,
      pageSize: 3,
      visibleNoticie: [],
      nextIdVideo: 2,
      currentPageVideo: 0,
      pageSizeVideo: 2,
      visibleVideo: [],
      selectVideo: Object
    }
  },
  components: {
    PaginateComponents
  },
  async created() {
    await api.get('/noticia').then(async (response) => {
      this.noticiaInfo = await response.data.data.notices
      storage.set('noticia', this.noticiaInfo)
      await this.updatedeVisibleNoticies()
    }).catch(async () => {
      this.noticiaInfo = await storage.get('noticia');
      await this.updatedeVisibleNoticies()
    });

    await api.get('/VideosYoutube').then(async (response) => {
      this.videoInfo = await response.data.data
      storage.set('VideosYoutube', this.videoInfo)
      if (this.videoInfo)
        this.selectVideo = this.videoInfo.body_header[0].src

      await this.updatedeVisibleVideo()


      // ytp-large-play-button

      // ytp-large-play-button
      //let elementDis = this.$el.querySelector(".ytp-large-play-button");
    }).catch(async () => {
      this.videoInfo = await storage.get('VideosYoutube');
      if (this.videoInfo)
        this.selectVideo = this.videoInfo.body_header[0].src
      await this.updatedeVisibleVideo()
    });


  },
  methods: {


    clickedIFram(element) {
      this.selectVideo = element.src

      /* var index = this.visibleVideo.indexOf(this.selectVideo)

       this.updatedeVisibleVideo()
       this.visibleVideo.splice((index - 1), 1);
       this.organizedArray(this.visibleVideo);*/

    },
    updatePage(pageNumber) {
      this.currentPage = pageNumber;
      this.updatedeVisibleNoticies();
    },
    updatedeVisibleNoticies() {
      this.visibleNoticie = this.noticiaInfo.slice(this.currentPage * this.pageSize, (this.currentPage * this.pageSize) + this.pageSize)
      if (this.visibleNoticie.length === 0 && this.currentPage > 0) {
        this.updatePage(this.currentPage - 1);
      }
    },

    updatePageVideo(pageNumber) {
      this.currentPageVideo = pageNumber;
      this.updatedeVisibleVideo();
    },
    updatedeVisibleVideo() {
      this.visibleVideo = this.videoInfo.body_header.slice(this.currentPageVideo * this.pageSizeVideo, (this.currentPageVideo * this.pageSizeVideo) + this.pageSizeVideo)
      if (this.visibleVideo.length === 0 && this.currentPageVideo > 0) {
        this.updatePageVideo(this.currentPageVideo - 1);
      }
    },
    organizedArray(arrais) {
      delete this.visibleVideo;
      arrais.forEach(e => {
        this.visibleVideo.add(e);
      })
    }

  }
}
</script>
<!-- height="530" -->
<style scoped>
.desktop-image {
  height: 220px;
  min-height: 220px;
  width: 100%;
}


.iframe-content {
  height: 530px
}

@media (max-width: 360px) {

  .iframe-content {
    height: 300px
  }
}

@media (max-width: 500px) {
  .mobile-height {
    height: 160px !important;
  }

  .internal-style {
    display: none;
  }


  iframe > {
    height: 30px !important;
    width: 30px !important;
    display: none;
  }

  .desktop-image {
    height: 280px;
    min-height: 280px;
    width: 100%;
  }

  .inner-box h4 {
    font-weight: bold;
    background-color: rgba(255, 255, 255, 1);
    padding: 10px;
  }

}


</style>
