<template>
  <div class="col-lg-3 col-md-6 col-sm-12 footer-column">
    <div class="footer-widget contact-widget" v-for="item in dataList" :key="item.id">
      <div class="widget-title">
        <h3>{{ item.title }}</h3>
      </div>
      <div class="widget-content">
        <ul class="info clearfix">
          <li>
            <h5>{{ item.title1 }}</h5>
            <p>{{item.context}}</p>
          </li>
          <li>
            <h5>{{ item.phone }}</h5>
            <p> {{item.numb}}</p>
          </li>
          <li>
            <h5>{{ item.email }}</h5>
            <p>{{ item.text }}</p>
          </li>

        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterColumn",
  props: {
    dataList: [],

  },
}
</script>

<style scoped>

</style>