<template>
  <section class="schedules-section sec-pad bg-color-1" id="Presidente-assembleia-nacional-angola">

    <div class="bg-layer" style="background-image: url(assets/images/background/schedule-bg.jpg);"></div>

    <!--    v-bind:style="'background-image:url(' + 'imagens/slider/noticias/' +schedulesData.subscribe_inner.imageName +'); height:280px' "-->
    <div class="auto-container">
      <div class="title-inner">
        <div class="row clearfix">
          <div class="col-lg-6 col-md-6 col-sm-12 title-column">
            <div class="sec-title light">
              <!-- <h6><i class="flaticon-star"></i><span>Our m </span></h6> -->
              <h2>{{ this.deputadosMesa.title }}</h2>
              <div class="title-shape"></div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 text-column">
            <div class="text">
              <p>{{ this.deputadosMesa.context }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="schedule-tab">
        <div class="tab-btn-box">
          <ul class="tab-btns schedule-tab-btns clearfix">
            <li :data-tab="item.dataTab" v-for="item in this.deputadosMesa.tabbtnslis"
                :class="'p-tab-btn mobile-style-botos '+ item.activeBtn"
                :key="item.dataTab">{{ item.context }}
            </li>
          </ul>
        </div>

        <div class="inner-content" v-if="this.deputadosMesa.subscribe_inner">
          <div class="row clearfix">
            <div class="col-lg-4 col-md-12 col-sm-12 form-column">
              <div class="subscribe-inner centred mobile-change">
                <div class="upper-box mobile-styles"
                     v-bind:style="'background-image:url(' + this.domine +'' +this.deputadosMesa.subscribe_inner.url +'); height:280px; min-height:280px' ">
                </div>
                <div class="lower-box">
                  <form method="post" class="subscribe-form">
                    <div class="form-group">
                      <!--<input type="email" name="email" placeholder="mailid@example.com" required="">-->
                    </div>
                    <div class="text mb-3">
                      <span class="font-weight-bolder text-uppercase">{{ this.deputadosMesa.subscribe_inner.h6 }}</span>
                      <!--   <h6>{{ schedulesData.subscribe_inner.h7 }}</h6> -->
                    </div>
                    <div class="form-group ">
                      <router-link to="presidente_assembleia_nacional" class="theme-btn mt-3 mb-3">{{
                          this.deputadosMesa.subscribe_inner.btnTitle
                        }}
                      </router-link>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div class="col-lg-8 col-md-12 col-sm-12 inner-column">
              <div class="p-tabs-content">
                <div class="p-tab active-tab" id="tab-1">
                  <div class="col-lg-12 col-md-12 col-sm-12 content-side">
                    <div class="blog-details-content">
                      <div class="inner-box">
                        <div class="text">
                          <h3 class="text-uppercase">{{ this.deputadosMesa.subscribe_inner.history.title }}</h3>
                          <p class="mb-0 pb-0" style="overflow: hidden; max-height: 332.2px; border:1px; text-overflow: ellipsis; "
                             v-html="this.deputadosMesa.subscribe_inner.history.p.substr(0,2000)"></p>

                          <ul class="social-style-one clearfix mt-3"
                              v-if="this.deputadosMesa.subscribe_inner.tab_entity.socialites">
                            <li v-for="socialite in this.deputadosMesa.subscribe_inner.tab_entity.socialites"
                                :key="socialite.id">
                              <!--     (item.url?item.url.substr((item.url.indexOf('//') + 2), item.url.length):'#')    -->

                              <a :href="'//' + socialite.href.substr((socialite.href.indexOf('//') + 2), socialite.href.length)"
                                 class="cursor-pointe content-socials mb-2 p-2"
                                 target="_blank">
                                <i class="fab fa-facebook-f fa-2x" v-if="socialite.icon === 'fab fa-facebook'"></i>
                                <i class="fab fa-youtube fa-2x" v-if="socialite.icon === 'fab fa-youtube'"></i>
                                <i class="fab fa-linkedin-in fa-2x" v-if="socialite.icon === 'fab fa-linkedin'"></i>
                                <i class="fab fa-twitter fa-2x" v-if="socialite.icon === 'fab fa-twitter'"></i>
                              </a>

                              <!--  <a :href="'//' + socialite.href" target="_blank" class=""><i :class="socialite.icon"></i></a> -->
                            </li>
                          </ul>
                          <div style="min-height: 20px">
                            <div class="share-box text-dark" style="margin-bottom: -10px; float: right">
                              <a :href="'whatsapp://send?text='+ this.deputadosMesa.subscribe_inner.h6 + '%0a - '+ this.deputadosMesa.subscribe_inner.h7 +  '%0a %0a Segue o link: %0a' + this.domineT">
                                Partilhar &nbsp; <i class="fas fa-share-alt"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                        <br>
                        <div class="points-block">
                          <div class="row clearfix">
                            <div class="col-lg-6 col-md-6 col-sm-12 single-column"
                                 v-for="item in this.deputadosMesa.subscribe_inner.history.btnList" :key="item.id">
                              <div class="single-item">
                                <p>{{ item.title }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="p-tab" id="tab-2">

                  <div class="two-column-carousel owl-carousel owl-theme owl-dots-none">
                    <schedule-block-one :data-list="this.tab2"/>
                  </div>
                </div>
                <div class="p-tab" id="tab-3">
                  <div class="two-column-carousel-info owl-carousel owl-theme owl-dots-none">
                    <schedule-block-one :data-list="this.tab3"/>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

  </section>
</template>

<script>
import ScheduleBlockOne from "@/components/subComponent/ScheduleBlockOne";
import Util from "../store/util";
import api from "../services/api";
import storage from "../store";

export default {
  name: "SchedulesSection",
  components: {
    "schedule-block-one": ScheduleBlockOne
  },
  data() {
    return {
      domine: Util.Domine,
      domineT: Util.DomineTo,
      deputadosMesa: [],
      tab2: [],
      tab3: []
    }
  },
  props: {
    schedulesData: Object
  },
  created() {
    api.get('/MessaPresidio', {
      proxy: false
    }).then(async (response) => {
      this.deputadosMesa = await response.data.data.data
      storage.set('MessaPresidio', this.deputadosMesa)
      this.tab2 = await response.data.data.data.tabs_content.tab2
      this.tab3 = await response.data.data.data.tabs_content.tab3

      storage.set('tab2', this.tab2)
      storage.set('tab3', this.tab3)

      await window.jQuery(document).ready((async function ($) {
        await $('.two-column-carousel').owlCarousel({
          loop: true,
          margin: 30,
          nav: true,
          smartSpeed: 1000,
          autoplay: 500,
          navText: ['<span class="fal fa-angle-left"></span>', '<span class="fal fa-angle-right"></span>'],
          responsive: {
            0: {
              items: 1
            },
            480: {
              items: 1
            },
            600: {
              items: 1
            },
            800: {
              items: 2
            },
            1024: {
              items: 2
            }
          }
        });
        await $('.two-column-carousel-info').owlCarousel({
          loop: true,
          margin: 30,
          nav: true,
          smartSpeed: 1000,
          autoplay: 500,
          navText: ['<span class="fal fa-angle-left"></span>', '<span class="fal fa-angle-right"></span>'],
          responsive: {
            0: {
              items: 1
            },
            480: {
              items: 1
            },
            600: {
              items: 1
            },
            800: {
              items: 2
            },
            1024: {
              items: 2
            }
          }
        });
        await $('.schedule-tab .schedule-tab-btns .p-tab-btn').on('click', function (e) {
          e.preventDefault();
          var target = $($(this).attr('data-tab'));

          if ($(target).hasClass('actve-tab')) {
            return false;
          } else {
            $('.schedule-tab .schedule-tab-btns .p-tab-btn').removeClass('active-btn');
            $(this).addClass('active-btn');
            $('.schedule-tab .p-tabs-content .p-tab').removeClass('active-tab');
            $(target).addClass('active-tab');
          }
        });
      }))
    }).catch(async () => {

      this.deputadosMesa = storage.get('MessaPresidio')
      this.tab2 = await storage.get('tab2')
      this.tab3 = await storage.get('tab3')

      await window.jQuery(document).ready((async function ($) {
        await $('.two-column-carousel').owlCarousel({
          loop: true,
          margin: 30,
          nav: true,
          smartSpeed: 1000,
          autoplay: 500,
          navText: ['<span class="fal fa-angle-left"></span>', '<span class="fal fa-angle-right"></span>'],
          responsive: {
            0: {
              items: 1
            },
            480: {
              items: 1
            },
            600: {
              items: 1
            },
            800: {
              items: 2
            },
            1024: {
              items: 2
            }
          }
        });
        await $('.two-column-carousel-info').owlCarousel({
          loop: true,
          margin: 30,
          nav: true,
          smartSpeed: 1000,
          autoplay: 500,
          navText: ['<span class="fal fa-angle-left"></span>', '<span class="fal fa-angle-right"></span>'],
          responsive: {
            0: {
              items: 1
            },
            480: {
              items: 1
            },
            600: {
              items: 1
            },
            800: {
              items: 2
            },
            1024: {
              items: 2
            }
          }
        });
        await $('.schedule-tab .schedule-tab-btns .p-tab-btn').on('click', function (e) {
          e.preventDefault();
          var target = $($(this).attr('data-tab'));

          if ($(target).hasClass('actve-tab')) {
            return false;
          } else {
            $('.schedule-tab .schedule-tab-btns .p-tab-btn').removeClass('active-btn');
            $(this).addClass('active-btn');
            $('.schedule-tab .p-tabs-content .p-tab').removeClass('active-tab');
            $(target).addClass('active-tab');
          }
        });
      }))

    });


  },
  mounted() {


    //MessaPresidio


  }
}
</script>

<style scoped>
@media (max-width: 500px) {
  .mobile-styles {
    height: 510px !important;
    min-height: 510px !important;
  }

  .mobile-style-botos {
    border-radius: 4px !important;
  }
}

.cursor-pointe {
  cursor: pointer;
}

.content-socials {
  background-color: #1b1e21;
  color: #fff;
  margin-right: 5px;
  padding: 5px 10px;
  border-radius: 100%;
}

@media (min-width: 920px) {
  .img-content {

    width: 340px;
    height: 240px;
    max-width: 340px;
    max-height: 240px;
  }
}
</style>
