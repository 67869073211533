<template>
    <div v-if="this.infoDeputy">
      <div class="row clearfix">
        <div class="col-lg-12 col-md-12 col-sm-12 big-column ">
          <div class="image-box">
            <div class="row clearfix">
              <div class="col-lg-12 col-md-12 col-sm-12 image-column">
                <figure class="image"><img :src="this.domine + this.infoDeputy.imgCapa"
                                           style="border-radius: 10px; width: 100%; max-height: 540px"
                                           alt=""></figure>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 text-column">
          <div class="text">
            <div class="group-title mt-3">
              <h3 v-if="this.infoDeputy.title">{{ this.infoDeputy.title }}</h3>
              <h3 v-else>Aguardo o título do histórico</h3>
              <div class="title-shape"></div>
            </div>
            <div v-if="this.infoDeputy.context">
              <p v-html="this.infoDeputy.context"></p>
              <div class="inner-box clearfix mb-3 mt-3">
                <ul class="social-style-one clearfix">
                  <li><a href="index.html"><i class="fab fa-facebook-f"></i></a></li>
                  <li><a href="index.html"><i class="fab fa-twitter"></i></a></li>
                  <li><a href="index.html"><i class="fab fa-google-plus-g"></i></a></li>
                  <li><a href="index.html"><i class="fab fa-linkedin-in"></i></a></li>
                </ul>
              </div>
            </div>
            <div v-else>
              <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <p>Aguarda as informações do historico do deputado selecionado</p>
            </div>

          </div>
        </div>
      </div>
    </div>
</template>

<script>

import api from "../../../services/api";
import Util from "../../../store/util";

export default {
  name: "DeputyView",
  data() {
    return {
      infoDeputy: Object,
      domine: Util.Domine
    }
  },
  computed: {},
  created() {
    this.requesti()
  },
  methods: {
    requesti: function () {
      try {
        let URL = this.$route.path.replace('/Deputados', '')

        api.get(URL).then(resp => {
          this.infoDeputy = resp.data.data.entity
        })
      } catch (e) {
        console.log(e)
      }


    }
  },
  mounted() {


  }
}
</script>
<style scoped>
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #0b1e2c;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

</style>
