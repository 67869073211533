<template>
  <div>
    <sub-header :data="this.headerInfo"/>

    <div class="container mt-5 pt-50 pb-140">
      <div class="form-inner">
        <div class="sec-title centred">
          <h2>Partilhe suas ideias</h2>
          <div class="title-shape"></div>

        </div>
        <form method="post" action="sendemail.php" id="contact-form" class="default-form" novalidate="novalidate">
          <div class="row">
            <div class="col-lg-8 col-md-9 col-sm-12 big-column">
              <div class="form-group">
                <input type="text" class="form-control" id="nameDisplay" name="username"
                       placeholder="Escreve o seu Nome"
                       required="required"
                       v-model="this.msgDataSend.name" aria-required="true">

              </div>


            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 big-column">
              <div class="form-group">
                <input type="text" class="form-control" name="email" id="emaildisplay" placeholder="Escreve o seu email"
                       required=""
                       v-model="this.msgDataSend.email" aria-required="true">
              </div>
            </div>


            <div class="col-lg-6 col-md-6 col-sm-12 big-column">
              <div class="form-group">
                <input type="text" class="form-control" name="phone" id="phonedisplay" placeholder="Número de telefone"
                       required=""
                       v-model="this.msgDataSend.phone" aria-required="true">
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 big-column">
              <div class="form-group">
                <input type="text" class="form-control" name="subject" id="subjectdisplay" required=""
                       placeholder="Assunto"
                       v-model="this.msgDataSend.subject" aria-required="true">
              </div>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12 big-column mb-3">
              <div class="form-group ">

                <select id="vueSelect" class="form-control" aria-label="Seleciona a Comissão de trabalho Espelizada"
                        v-model="this.msgDataSend.comissao">
                  <!--                  <option v-for="item in this.menuFull" :key="item.id" :value="this.item.context"></option>-->
                </select>

              </div>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12 big-column">
              <div class="form-group">
                <textarea name="message" class="form-control" id="msndisplay" rows="6"
                          placeholder="Escreve a sua mensagem ..."
                          v-model="this.msgDataSend.msg"></textarea>
              </div>
            </div>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12 big-column">
            <div class="message-btn centred">
              <button class="theme-btn" type="button" v-on:click="this.sending">Enviar Mensagem</button>

            </div>
          </div>
          <div class="alert alert-success mt-5" v-if="!this.flag">
            A sua mensagem foi enviada com sucesso!
          </div>
        </form>
      </div>

    </div>
  </div>
</template>

<script>

import Util from "../store/util";
import SubHeader from "./subComponent/SubHeader";
import api from "../services/api";

export default {
  name: "Contact",
  data() {
    return {
      Domine: Util.Domine,
      flag: true,
      msgDataSend: {},
      menuFull: [],
      headerInfo: {
        img: '/blog/capa/33/X2ygaMd3aPvX4dhL3kz3qqLsXzqY6nCaXqWZrzkd.png'
      }
    }
  },
  beforeCreate() {
    // this.initQuerys();
  },
  created() {
    // this.initQuerys()
  },
  mounted() {
    this.initQuerys();
  },
  methods: {
    sending: async function () {
      if (!this.msgDataSend.name) {
        window.jQuery(document).ready((async function ($) {

          $('#nameDisplay').addClass('is-invalid')
        }));
        throw 'not found'
      }
      if (!this.msgDataSend.email) {
        window.jQuery(document).ready((async function ($) {

          $('#emaildisplay').addClass('is-invalid')
        }));
        throw 'not found'
      }
      if (!this.msgDataSend.phone) {
        window.jQuery(document).ready((async function ($) {

          $('#phonedisplay').addClass('is-invalid')
        }));
        throw 'not found'
      }
      if (!this.msgDataSend.subject) {
        window.jQuery(document).ready((async function ($) {

          $('#subjectdisplay').addClass('is-invalid')
        }));
        throw 'not found'
      }
      if (!this.msgDataSend.subject) {
        window.jQuery(document).ready((async function ($) {

          $('#subjectdisplay').addClass('is-invalid')
        }));
        throw 'not found'
      }

      this.flag = false;
      // send notification to api server _AN
      this.msgDataSend.domine = 'https://portaladmin.parlamento.ao';
      await api.post('message', this.msgDataSend).then(() => {
        this.flag = false;

      }).catch(error => {
        console.error(error);
      });


    },
    initQuerys: function () {

      window.jQuery(document).ready((async function ($) {
        //  $('select').niceSelect();

        api.get('mainmenufull?format=json', {
          proxy: true
        }).then(async e => {
          const menuFullA = await e.data.data.mainMenu[3].elements;
          menuFullA.forEach((a) => {

            $("#vueSelect").append('<option class="form-control" value=" ' + a.context + ' ">' + a.context + '</option>');
          })


        })
        //$("#vueSelect").niceSelect('update')
        //$("#vueSelect").removeAttr( 'style' );
        //$( ".nice-select" ).css('display','none')
        // $("#vueSelect").css();
      }))

      console.log(this.menuFull);
    }
  },
  components: {
    "sub-header": SubHeader,
  }
}


</script>

<style scoped>


</style>