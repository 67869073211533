<template>
  <div id="search-popup" class="search-popup">
    <div class="close-search"><span>x</span></div>
    <div class="popup-inner">
      <div class="overlay-layer"></div>

      <div class="search-form">

        <form method="post" action="index.html">
          <div class="form-group">

            <fieldset>

              <input type="search" class="form-control" name="search-input" value="" placeholder="buscar informações"
                     required>
              <input type="submit" value="Pesquisar" class="theme-btn style-four">
            </fieldset>
          </div>
        </form>
        <!--        <h3>Pesquisas resentes</h3>
                <ul class="recent-searches">
                  <li><a href="index.html">Finance</a></li>
                  <li><a href="index.html">Idea</a></li>
                  <li><a href="index.html">Service</a></li>
                  <li><a href="index.html">Growth</a></li>
                  <li><a href="index.html">Plan</a></li>
                </ul>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchPopup"
}
</script>

<style scoped>

</style>