<template>
  <div class="sidebar-widget post-widget">
    <div class="widget-title">
      <h3>Destaque informativo</h3>
    </div>
    <div class="">
      <div class="post" v-for="itennsn in this.noticieVisibleOn" :key="itennsn.id">
        <figure class="post-thumb"
                :style="'background-image: url('+dominio+ itennsn.img+'); background-repeat: no-repeat; background-position: center; background-size: 300px 100%; background-position-x: 40% '">

          <router-link :to="{name: 'Contexto', params:{documentario:'Noticia_'+ itennsn.id}}"
                       style="background-position-x: 60%; ">

          </router-link>
        </figure>

        <h6 style="height: 50px ">
          <router-link :to="{name: 'Contexto', params:{documentario:'Noticia_'+ itennsn.id}}">{{
              itennsn.context
            }}
          </router-link>
        </h6>
        <p>
          <i class="far fa-calendar"></i><span v-html="this.convertData(itennsn.dataEvento ?? null)"></span>
        </p>
      </div>

    </div>
  </div>
</template>

<script>

import api from "../../../services/api";
import Util from "../../../store/util";
import moment from "moment";

export default {
  name: "PostWidgetNoticies",
  data() {
    return {
      noticieList: [],
      noticieVisibleOn: [],
      dominio: Util.Domine
    }
  },
  async created() {
    await api.get('/noticia').then(async (response) => {
      this.noticieList = await response.data.data.notices
      this.noticieVisibleOn = this.noticieList.slice(0, 3)
    });
  }, methods: {
    convertData($attr) {
      let diaSemana = '-- -- / --';
      if ($attr)
        diaSemana = moment($attr).locale('pt-br').format('llll');


      return diaSemana;

    }
  }
}
</script>

<style scoped>

</style>
