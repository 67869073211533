<template>
  <div class="solution-block-one" v-for="item in dataList" :key="item.id">
    <div class="inner-box">
      <div class="icon-box"><i :class="item.icon"></i></div>
      <h4>{{ item.title }}</h4>
      <p>{{ item.paragraph }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "SolutionBlockOne",
  props: {
    dataList: [],

  }
}
</script>

<style scoped>

</style>


