import config from "../services/config";
import IndexDataServer from "../json/IndexData.json";
import FooterMain from "../json/MainFooter.json"
import MainDataYoutube from "../json/VideosYoutube.json"
import Noticia from "../json/Noticia.json"
import SiteContents from "../json/SiteContent.json"
import SliderInfo from "../json/Slider.json"
import DefinicaoComposicao from "../json/DeficaoComposicao.json"
import Multimedia from "../json/MultimediaJSON"

const historico = "Ola miguel";
const contexto = '';
const urlImage = 'C:/portal_parlmanto';

const Domine = config.getServerHost() + "/storage/"
const DomineTo = config.getServerHost() + "/"

export default {
    Domine,
    DomineTo,
    historico,
    contexto,
    IndexDataServer,
    FooterMain,
    MainDataYoutube,
    urlImage,
    Noticia,
    SiteContents,
    SliderInfo,
    DefinicaoComposicao,
    Multimedia
}
