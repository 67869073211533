<template>
  <div>
    <page-title></page-title>
  </div>
</template>

<script>
/* eslint-disable */
import PageTitle from "../components/PageTitle";

export default {
  name: "Multimidea",
  components: {
    PageTitle
  }
}
</script>

<style scoped>

</style>
