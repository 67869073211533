<template>
  <div>

    <header class="main-header style-one" id="scrollFromMenu">
      <!-- header-top -->
      <div class="header-top d-none d-sm-block justify-content-between" style="">
        <div class="auto-container justify-content-between">
          <div class="top-inner clearfix">
            <div class="pull-left clearfix">
              <div class="weathre-box pl-0">
                <router-link to="/">{{ data.date_region }}</router-link>
              </div>
              <ul class="links-box clearfix" v-if="data.links_box">
                <li-config :list-links="data.links_box" styles-class="info-list clearfix"/>
              </ul>
            </div>
            <div class=" pull-right clearfix">
              <ul class="info-list clearfix weathre-box" v-if="this.data.infor_list">
                <li class="" v-for="itemInterator in data.infor_list" :key="itemInterator.id">
                  <i v-if="itemInterator.id" :class="itemInterator.icon" style="margin-top: 5px; font-size: 14px"></i>
                  <a :href="itemInterator.url" target="_blank">{{ itemInterator.context }}</a>
                </li>
              </ul>
              <ul class="social-links clearfix" v-if="this.data.socialites_list">
                <li v-for="item in data.socialites_list" :key="item.id">
                  <a :href="(item.url?item.url:'#')" target="_blank"><i :class="item.icon"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- header-lower -->
      <div class="header-lower" style=" height: 100px">
        <div class="auto-container">
          <div class="outer-box clearfix">
            <div class="logo-box pull-left ">

              <figure class="logo ">
                <a href="#" class="d-none"><img src="assets/images/logo.png" alt=""></a>
              </figure>
            </div>

            <div class="menu-area clearfix pull-right">
              <!--Mobile Navigation Toggler-->
              <div class="mobile-icon" style="position: absolute; left: 0px; top: 10px; ">
                <router-link to="/"><img src="../assets/LogoAN.jpeg" alt=""
                                         style="width: 90px!important; border-radius: 100%"></router-link>
              </div>


              <main-menu :data="this.menuDados"/>

              <main-right-content :data="this.data"/>

            </div>
          </div>
        </div>
      </div>

      <!--sticky Header-->


      <mobile-menu/>

      <side-bar/>

      <search-popup/>
    </header>

  </div>
</template>

<script>
import MainMenu from "./subComponent/MainMenu"
import LiList from "./subComponent/LiList";
import MenuRightContent from "./subComponent/MenuRightContent";
import MainHeaderJson from "../json/MainHeader.json"
import MobileMenu from "./MobileMenu";
import SideBar from "./SideBar";
import SearchPopup from "./SearchPopup";
import moment from 'moment';
import api from "../services/api";
import storage from "../store";

export default {
  name: "MainHeader",
  components: {
    SearchPopup,
    SideBar,
    "main-menu": MainMenu,
    MobileMenu,
    'li-config': LiList,
    "main-right-content": MenuRightContent
  },
  data() {
    return {
      data: MainHeaderJson,
      nowsEls: Date,
      menuDados:[]
    }
  },

  mounted() {
    this.initserver()
    this.initquery()
    this.scrollContent()
  },
  methods: {
    initserver: async function () {

      try {
        if (api) {
          await api.get('/MainHeader').then(async response => {
            this.data = await response.data.data
            storage.remove('MainHeader');
            storage.set('MainHeader', this.data)
          }).catch(() => {
            console.log("request network not found")
          });

          await api.get('/MainMenu').then(async resp => {
            this.menuDados = await resp.data.data;
            storage.remove('MainMenu');
            storage.set('MainMenu', this.menuDados)
          }).catch(() => {
            console.log("request network not found")
          })
        }
      } catch (e) {
        console.log("request network not found")
      }

    },
    initquery: function () {
      try {
        //this.nowsEls = moment().locale('pt-br').format('MMMM Do YYYY');
        this.nowsEls = moment().locale('pt-br').format('MMMM DD YYYY');

      } catch (e) {
        console.log(e)
      }
    },
    scrollContent() {
      window.jQuery(document).ready((async function ($) {
        //var windowpos = $(window).scrollTop();
        //var siteHeader = $('#scrollFromMenu');


        $(window).on('scroll', function () {


          if ($(window).scrollTop() >= 100) {
            $('#scrollFromMenu').addClass("fixed-hader-menu");
          } else {
            $('#scrollFromMenu').removeClass("fixed-hader-menu");
          }
        });


      }))
    }
  }


}
</script>

<style scoped>
@media (min-width: 1201px) {
  .mobile-icon {
    display: none;
  }
}

.fixed-hader-menu {
  position: fixed !important;
  top: -50px;
  width: 100%;
  z-index: 2000;
  background-color: white
}

@media (min-width: 720px) {
  .main-header {
    min-width: 720px !important;
  }
}
</style>
