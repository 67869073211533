<template>
  <div>
    <banner-section/>
    <activities-section :itens="this.activitiesSection"/>
    <blog-page :data="this.blogPag" :data-video="this.VideoDestaque"/>
    <schedules-section :schedules-data="this.scheduleSection"/>
    <about-section :about-section="this.aboutSection"/>
    <solutions-section :schedules-data="this.SolutionsSection"/>

    <!-- mensagem do roda pe  -->
    <div id="divOnOf" class="contentMain" v-if="this.title">
      <div class="row">
        <div id="buttonEle" class="text-white childenContent col-2"
             style="float: left; min-height: 36px!important; min-width: 36px!important;max-height: 36px!important; max-width: 36px!important;">
          <i class="fa fa-bell"></i>
          <input type="checkbox" id="cheControl" checked hidden>
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <marquee direction="left" width="94.3%" class="col">
            <span class="text-white ml-0 ml-sm-3">
              {{ this?.title + ' - ' + this?.context }}
            </span>
        </marquee>
      </div>
    </div>

  </div>
</template>

<script>

import BannerSection from "../../../components/BannerSection";
import IndexDataJSON from "../../../json/IndexData.json"
import ActivitiesSection from "../../../components/ActivitiesSection";
import AboutSection from "../../../components/AboutSection";
import SchedulesSection from "../../../components/SchedulesSection";
import BlogPage from "../../../components/BlogPage";
import SolutionsSection from "../../../components/SolutionsSection";
import Util from "@/store/util";
import storage from "../../../store";
import api from "@/services/api";


export default {
  name: "IndexViews",
  components: {
    "banner-section": BannerSection,
    "activities-section": ActivitiesSection,
    "about-section": AboutSection,
    "schedules-section": SchedulesSection,
    "blog-page": BlogPage,
    "solutions-section": SolutionsSection
  },
  data() {
    return {
      title: String,
      context: String,
      data: IndexDataJSON,
      activitiesSection: IndexDataJSON.activitiesSection,
      aboutSection: IndexDataJSON.aboutSection,
      blogPag: IndexDataJSON.blogPag,
      SolutionsSection: IndexDataJSON.SolutionsSection,
      VideoDestaque: IndexDataJSON.blogPag,
      scheduleSection: (storage.get('MessaPresidio') ?? [])
    };
  }, created() {


    api.get('/Notificacao', {
      proxy: false
    }).then(async responce => {

      this.title = responce.data.data.title
      this.context = responce.data.data.context
      this.notifyMe((responce.data.data.title??'Bem vindo no Portal do Parlamento angola'), responce.data.data.context)

    })


    this.activitiesSection = Util.IndexDataServer.activitiesSection
    this.aboutSection = Util.DefinicaoComposicao.data
    this.blogPag = Util.Noticia.notices
    this.SolutionsSection = Util.IndexDataServer.solutionsSection
    this.VideoDestaque = Util.MainDataYoutube


  }, mounted() {

    window.jQuery(document).ready((async function ($) {

      $('#divOnOf').css("margin-right", "-37.5%");
    }))
    window.jQuery(document).ready((async function ($) {
      $('#buttonEle').on('click', () => {
        
        Notification.requestPermission();

        if (!Notification) {
          alert('Active as notificações para poder receber as actualizações da A.N.'); //if browser is not compatible this will occur
          return;
        }
        if (Notification.permission !== "granted")
          Notification.requestPermission();

        $('#cheControl').click();
        if ($('#cheControl').is(':checked')) {
          $('#divOnOf').css("margin-right", "");
        } else {
          $('#divOnOf').css("margin-right", "-37.5%");
        }

      })


    }));
    window.jQuery(document).ready((async function ($) {

      if ($('.loader-wrap').length) {
        $('.loader-wrap').delay(1000).fadeOut(500);
      }

    }))
  }
}
</script>

<style scoped>
.contentMain {
  position: fixed;
  right: -15px;
  height: 50px;
  width: 40%;
  bottom: 50px;
  background-color: rgba(32, 31, 31, 0.89);
  z-index: 1000;
  min-width: 220px;
  border-radius: 20px 20px 20px 20px;
  box-shadow: rgba(11, 46, 19, 0.8);
  padding: 10px
}

.childenContent {
  background-color: #e31515;
  float: left;
  display: inline;
  cursor: pointer;
  padding: 8px;
  text-align: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-top: -5px;
  margin-left: -4px
}

@media (max-width: 600px) {
  .margin-top {
    margin-top: -16px !important;
  }
}
</style>
