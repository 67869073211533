<template>
  <div style="min-height: 750px">
<!--    <div class="loader-wrap">
      <div class="preloader">
        <div class="preloader-close">Preloader Close</div>
        <div id="handle-preloader" class="handle-preloader">
          <div class="animation-preloader">
            <div class="spinner"></div>
            <div class="txt-loading">
                            <span data-text-preloader="P" class="letters-loading">
                                P
                            </span>
              <span data-text-preloader="A" class="letters-loading">
                                A
                            </span>
              <span data-text-preloader="R" class="letters-loading">
                               R
                            </span>
              <span data-text-preloader="L" class="letters-loading">
                               L
                            </span>
              <span data-text-preloader="A" class="letters-loading">
                                A
                            </span>
              <span data-text-preloader="M" class="letters-loading">
                                M
                            </span>
              <span data-text-preloader="E" class="letters-loading">
                                E
                            </span>
              <span data-text-preloader="N" class="letters-loading">
                               N
                            </span>
              <span data-text-preloader="T" class="letters-loading">
                                T
                            </span>
              <span data-text-preloader="O" class="letters-loading">
                                O
                            </span>
            </div>
          </div>
        </div>
      </div>
    </div>-->
<!--    <section class="events-list sec-pad-2">
      <div class="auto-container">
        <div class="event-list-content">

          <div class="schedule-inner">
            <div class="schedule-block-three">
              <div class="inner-box">
                <div class="inner">
                  <div class="schedule-date">
                    <h2>26 <span class="year">January’2021</span><span class="symple">th</span></h2>
                    <ul class="list clearfix">
                      <li><i class="flaticon-clock-circular-outline"></i>12.00 pm - 2.00 pm</li>
                      <li><i class="flaticon-gps"></i>333 Bridge Road, San Antonio</li>
                    </ul>
                  </div>
                  <div class="text">
                    <span class="category"><i class="flaticon-star"></i>Celebration</span>
                    <h3><a href="event-details.html">Scating School holiday Program at San Antonio</a></h3>
                    <p>Righteous indignation & dislike men who are so beguiled demoralized by the charms pleasure.</p>
                    <div class="link"><a href="event-details.html">Read More<i class="flaticon-right-arrow"></i></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="schedule-block-three">
              <div class="inner-box">
                <div class="inner">
                  <div class="schedule-date">
                    <h2>14 <span class="year">February’2021</span><span class="symple">th</span></h2>
                    <ul class="list clearfix">
                      <li><i class="flaticon-clock-circular-outline"></i>10.00 am - 6.30 pm</li>
                      <li><i class="flaticon-gps"></i>182 St Georges Road, San Antonio</li>
                    </ul>
                  </div>
                  <div class="text">
                    <span class="category"><i class="flaticon-star"></i>Art & Culture</span>
                    <h3><a href="event-details.html">Public Affairs & Public Safety Committee Meeting</a></h3>
                    <p>Praising pain was born will give you a complete account of the system expound the actual.</p>
                    <div class="link"><a href="event-details.html">Read More<i class="flaticon-right-arrow"></i></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="schedule-block-three">
              <div class="inner-box">
                <div class="inner">
                  <div class="schedule-date">
                    <h2>14 <span class="year">February’2021</span><span class="symple">th</span></h2>
                    <ul class="list clearfix">
                      <li><i class="flaticon-clock-circular-outline"></i>10.00 am - 6.30 pm</li>
                      <li><i class="flaticon-gps"></i>182 St Georges Road, San Antonio</li>
                    </ul>
                  </div>
                  <div class="text">
                    <span class="category"><i class="flaticon-star"></i>Art & Culture</span>
                    <h3><a href="event-details.html">Public Affairs & Public Safety Committee Meeting</a></h3>
                    <p>Praising pain was born will give you a complete account of the system expound the actual.</p>
                    <div class="link"><a href="event-details.html">Read More<i class="flaticon-right-arrow"></i></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="pagination-wrapper centred">
          <ul class="pagination clearfix" style="float: right">
            <li><a href="event.html"><i class="far fa-angle-double-left"></i></a></li>
            <li><a href="event.html" class="current">1</a></li>
            <li><a href="event.html">2</a></li>
            <li><a href="event.html"><i class="far fa-angle-double-right"></i></a></li>
          </ul>
        </div>
      </div>
    </section>-->
  </div>
</template>

<script>

export default {
  name: "IntervencaoView"
  , mounted() {
    window.jQuery(document).ready((async function ($) {

      if ($('.loader-wrap').length) {
        $('.loader-wrap').delay(1000).fadeOut(500);
      }

    }))

  }
}
</script>

<style scoped>

</style>
