<template>
  <div style="background: #000">
    <section class="banner-section style-one" v-if="carrocelCode"
             style=" transition: .35s!important; min-height: 200px; background: #000 ">
      <div class="banner-carousel owl-theme owl-carousel owl-dots-none" id="banner-carousel"
           style="background-image:linear-gradient(to right, rgba(0,0,0,0.8) 95%,rgba(0,0,0,1) 100%), url('img/icons/image-background.jpg'); background-size: 100% "
           v-html="carrocelCode"></div>
    </section>
    <div style="min-height: 200px; background-color: rgba(232,235,237,0.92);  transition:  .35s!important;"
         class="text-center" v-else>
      <div class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
import Util from "../store/util";
import api from "../services/api";
import storage from "../store";

export default {
  name: "BannerSection",
  data() {
    return {
      Domine: Util.Domine,
      SliderInfo: (storage.get('Slider') ?? []),
      carrocelCode: ""
    }
  },
  props: {
    itens: [],
  },

  mounted() {
    this.initCarrocel()
  },
  methods: {
    initCarrocel: function () {
      api.get('/Slider').then(async (response) => {
        this.SliderInfo = response.data.data.data;
        storage.set('Slider', this.SliderInfo)
        await this.SliderInfo.forEach((item) => {

          this.carrocelCode += " <div class='slide-item'>" +
              "<div class='image-layer' style='background-image:url(" + this.Domine + item.url + ")'></div>" +
              "   <div class='auto-container'> " +
              "     <div class='content-box'>" +
              "       <div class='text' style='height:74px!important; text-overflow: ellipsis;  overflow: hidden;'> " +
              "         <h2 class='text-white font-weight-bolder'>" + item.h1 + "</h2> " +
              "       </div>" +
              "       <br>\n" +
              "       <div class='text ' style='height:100px!important; text-overflow: ellipsis;  overflow: hidden;'> " +
              "         <p class='font-weight-light'>" + item.p + "</p>" +
              "       </div>" +
              "       <br>" +
              "       <div class='btn-box'>" +
              "         <a href='Slider_" + item.id + "' class='theme-btn'>mais informação</a>" +
              "       </div>" +
              "     </div>" +
              "   </div>" +
              "</div>";
        })
        await window.jQuery(document).ready((async function ($) {

          var owl = $("#banner-carousel");
          owl.owlCarousel({
            loop: true,
            margin: 0,
            nav: true,
            animateOut: 'fadeOut',
            animateIn: 'fadeIn',
            active: true,
            smartSpeed: 1000,
            autoplay: 6000,
            navText: ['<span class="fal fa-angle-left"></span>', '<span class="fal fa-angle-right"></span>'],
            responsive: {
              0: {
                items: 1
              },
              600: {
                items: 1
              },
              800: {
                items: 1
              },
              1024: {
                items: 1
              }
            }
          });
        }));
      }).catch(() => {
        this.localInformatio()
      });

    },
    async localInformatio() {


      await this.SliderInfo.forEach((item) => {
        this.carrocelCode += " <div class='slide-item'>" +
            "<div class='image-layer' style='background-image:url(" + this.Domine + item.url + ")'></div>" +
            "   <div class='auto-container'> " +
            "     <div class='content-box'>" +
            "       <div class='text' style='height:74px!important; text-overflow: ellipsis;  overflow: hidden;'> " +
            "         <h2 class='text-white font-weight-bolder'>" + item.h1 + "</h2> " +
            "       </div>" +
            "       <br>\n" +
            "       <div class='text ' style='height:100px!important; text-overflow: ellipsis;  overflow: hidden;'> " +
            "         <p class='font-weight-light'>" + item.p + "</p>" +
            "       </div>" +
            "       <br>" +
            "       <div class='btn-box'>" +
            "         <a href='Slider_" + item.id + "' class='theme-btn'>mais informação</a>" +
            "       </div>" +
            "     </div>" +
            "   </div>" +
            "</div>";
      })
      await window.jQuery(document).ready((async function ($) {


        var owl = $("#banner-carousel");
        owl.owlCarousel({
          loop: true,
          margin: 0,
          nav: true,
          animateOut: 'fadeOut',
          animateIn: 'fadeIn',
          active: true,
          smartSpeed: 1000,
          autoplay: false,
          navText: ['<span class="fal fa-angle-left"></span>', '<span class="fal fa-angle-right"></span>'],
          responsive: {
            0: {
              items: 1
            },
            600: {
              items: 1
            },
            800: {
              items: 1
            },
            1024: {
              items: 1
            }
          }
        });
      }));
    }
  }
}
</script>

<style scoped>
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #0b1e2c;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

</style>
