<template>
  <div>

    <section class="page-title" style="background-image: url(https://www.verangola.net/va/images/cache/750x380/crop/images%7Ccms-image-000028069.jpg);">
      <div class="auto-container">
        <div class="content-box">
          <div class="title centred">
            <h1 class="text-uppercase">Multimédia</h1>
          </div>
          <ul class="bread-crumb clearfix">
            <li>
              <router-link :to="{name: 'AllItem'}">Todos</router-link>
            </li>
            <li>
              <router-link :to="{name: 'Imagens'}">Imagens</router-link>
            </li>
            <li>
              <router-link :to="{name:'VideoViews'}">Videos</router-link>
            </li>
            <li>
              <router-link :to="{name: 'AudioViews'}">Audios</router-link>
            </li>
          </ul>
        </div>
      </div>

    </section>
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: "PageTitle",
  mounted() {
    window.jQuery(document).ready((async function ($) {

      if ($('.loader-wrap').length) {
        $('.loader-wrap').delay(1000).fadeOut(500);
      }

    }))

  }
}
</script>

<style scoped>

</style>
